import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Card, Grid, Typography, Paper, Avatar, Divider, Button, CircularProgress } from '@mui/material';
import Header from 'components/Header';
import { format } from 'date-fns';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';
import { green, brown } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const ViewForms = ({ token }) => {
  const { t } = useTranslation();
  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  const location = useLocation();
  const { formData } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  if (!formData || !Array.isArray(formData) || formData.length === 0) {
    return <Typography>{t('No form data available.')}</Typography>;
  }

  const handleDownload = async (form_id, file_name, token) => {
    console.log('Downloading file:', file_name);
    
    setLoading(true);
    setError(null);
  
    try {
      const response = await axios.post(
        'https://api.syban-datacloud.com/files/download/',
        {
          form_id: form_id,
          file_name: file_name,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: 'blob',
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error while downloading the file:', error);
      setError(t('Failed to download the file. Please try again.'));
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Box m="1.5rem 2.5rem" sx={{ backgroundColor: brown[50], minHeight: '100vh', direction: direction }}>
      <Header title={t("Form Details")} />
      {formData.map((form, index) => (
        <Paper
          key={index}
          elevation={3}
          sx={{
            padding: '20px',
            marginBottom: '20px',
            marginTop: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar sx={{ bgcolor: green[100], marginRight: '1rem' }}>
              <DescriptionIcon />
            </Avatar>
            <Typography variant="h4" gutterBottom>
              {t(`${form.title}`)}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={2} sx={{ direction: direction }}>
            <PersonIcon sx={{ marginRight: '0.5rem' }} />
            <Typography variant="h6" color="textSecondary">
              {t('Uploaded by')}: {form.uploader_username}
            </Typography>
          </Box>

         {/* <Box display="flex" alignItems="center" mb={2} sx={{ direction: direction }}>
            <CalendarTodayIcon sx={{ marginRight: '0.5rem' }} />
            <Typography variant="subtitle1" color="textSecondary" sx={{ direction: direction }}>
              {t('Upload Date')}: {format(new Date(form.upload_date?.$date || ""), 'yyyy-MM-dd')}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={2} sx={{ direction: direction }}>
            <AccessTimeIcon sx={{ marginRight: '0.5rem' }} />
            <Typography variant="subtitle1" color="textSecondary">
              {t('Last Modified')}: {format(new Date(form.last_modified_date?.$date || ""), 'yyyy-MM-dd')}
            </Typography>
        </Box>*/}

          {/* File Download Buttons */}
          <Box mb={2} sx={{ direction: direction }}>
            {form.file_urls.map((fileName, fileIndex) => (
              <Box key={fileIndex} mb={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={loading ? <CircularProgress size={20} /> : <CloudDownloadIcon />}
                  onClick={() => handleDownload(form.form_id, fileName, token)}
                  disabled={loading}
                >
                  {t(`Download File: ${fileName}`)}
                </Button>
              </Box>
            ))}
            {error && <Typography color="error">{error}</Typography>}
          </Box>

          <Divider sx={{ marginY: '1rem' }} />

          {form.data.map((section, sectionIndex) => (
            <Box key={sectionIndex} mb="20px" sx={{ direction: direction }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  backgroundColor: '#6B8A7A',
                  color: '#ffffff',
                  padding: '10px',
                  borderRadius: '8px',
                }}
              >
                {t(`${section.section_name}`)}
              </Typography>
              <Grid container spacing={2}>
                {section.fields.map((field, fieldIndex) => (
                  <Grid item xs={12} sm={6} key={fieldIndex}>
                    <Card
                      sx={{
                        padding: '15px',
                        marginBottom: '10px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {t(`${field.field_name}`)}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: 'text.secondary', marginTop: '0.5rem' }}
                      >
                        {t(`${field.value}`)}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Paper>
      ))}
    </Box>
  );
};

ViewForms.propTypes = {
  token: PropTypes.string.isRequired,
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      form_description: PropTypes.string,
      language: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          section_name: PropTypes.string,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              field_name: PropTypes.string,
              field_type: PropTypes.string,
              mandatory: PropTypes.bool,
              value: PropTypes.string,
            })
          ),
        })
      ),
      uploader_username: PropTypes.string,
      upload_date: PropTypes.shape({
        $date: PropTypes.string,
      }),
      last_modified_date: PropTypes.shape({
        $date: PropTypes.string,
      }),
      file_urls: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default ViewForms;
