import React, { useState } from 'react';
import axios from 'axios';
import { 
  Box, Button, TextField, Typography, CircularProgress, Paper, Snackbar, 
  Alert, LinearProgress, Stepper, Step, StepLabel, StepIconProps, Stack, useMediaQuery
} from '@mui/material';
import { CheckCircle, Cancel, HourglassEmpty } from '@mui/icons-material';
import { green, red, amber } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const TrackWaste = () => {
  const [batchId, setBatchId] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects if it's a small screen

  const handleTrackWaste = async () => {
    setLoading(true);
    setError(null);
    setData(null);
    try {
      const response = await axios.post(
        `https://api.syban-datacloud.com/dashboard/track/`,
        { batch_id: batchId },
        {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setData(response.data);
    } catch (err) {
      setError('Failed to fetch data');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const getProgress = () => {
    if (!data) return 0;
    const totalSteps = Object.keys(data).length;
    const completedSteps = Object.values(data).filter(value => value).length;
    return (completedSteps / totalSteps) * 100;
  };

  const StepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;
    let color = active ? amber[500] : completed ? green[500] : red[500];
    let IconComponent = active ? HourglassEmpty : completed ? CheckCircle : Cancel;
    return <IconComponent sx={{ color }} />;
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: isMobile ? 2 : 4, 
        borderRadius: 3, 
        backgroundColor: "#f5f5f5", 
        width: isMobile ? '100%' : 'auto',
        margin: '0 auto',
      }}
    >
      <Typography 
        variant="h4" 
        gutterBottom 
        align="center" 
        sx={{ 
          fontSize: isMobile ? "1.2rem" : "1.5rem", 
          fontWeight: "bold", 
          color: green[800], 
          mb: isMobile ? 2 : 4 
        }}
      >
        {t("Track Waste Batch")}
      </Typography>

      <Stack direction="column" spacing={2} alignItems="center">
        <TextField
          label={t("Batch ID")}
          variant="outlined"
          value={batchId}
          onChange={(e) => setBatchId(e.target.value)}
          fullWidth
          sx={{ maxWidth: 500 }}
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleTrackWaste} 
          disabled={loading} 
          sx={{ maxWidth: 500, height: 50 }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : t("Track")}
        </Button>
      </Stack>

      {error && (
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {data && (
        <Box mt={isMobile ? 2 : 4}>
          <Typography 
            variant="h5" 
            gutterBottom 
            align="center" 
            sx={{ 
              color: green[700], 
              mb: isMobile ? 1 : 2,
              fontSize: isMobile ? "1rem" : "1.25rem" 
            }}
          >
            {t("Tracking Steps")}
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={getProgress()} 
            sx={{ 
              mb: isMobile ? 2 : 4, 
              height: 10, 
              borderRadius: 5 
            }} 
          />
          <Stepper alternativeLabel orientation={isMobile ? "vertical" : "horizontal"}>
            {Object.keys(data).map((step, index) => (
              <Step key={index} completed={data[step]} active={!data[step]}>
                <StepLabel StepIconComponent={StepIcon}>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      fontWeight: 'bold', 
                      fontSize: isMobile ? '0.9rem' : '1rem'
                    }}
                  >
                    {step.replace(/([A-Z])/g, ' $1').trim()}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </Paper>
  );
};

export default TrackWaste;
