import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Paper,
  Typography,
  CircularProgress,
  Avatar,
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from 'components/Header';
import Footer from 'homepage/Footer/Footer';

import { useTranslation } from 'react-i18next';
import i18n from "i18next";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [forgotDialogOpen, setForgotDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [resetKey, setResetKey] = useState('');
  const [password, setPassword] = useState('');
  const token = localStorage.getItem('token');

  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://api.syban-datacloud.com/auth/users/me/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const userData = response.data;
        setUser(userData);
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
        setRole(userData.role);

        // Fetch profile picture
        const profileResponse = await axios.get('https://api.syban-datacloud.com/accounts/userprofile/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setUser((prevUser) => ({ ...prevUser, avatar: profileResponse.data }));
        console.log(profileResponse)
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [token]);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);

      const formData = new FormData();
      formData.append('Image', file);

      try {
        const response = await axios.post('https://api.syban-datacloud.com/accounts/userprofile/', formData, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setUser({ ...user, avatar: response.data.avatar });
        setError(null);
        setPreview(null);
        URL.revokeObjectURL(previewURL); // Free memory
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        setError('Error uploading profile picture');
        setPreview(null);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete('https://api.syban-datacloud.com/accounts/userprofile/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setUser({ ...user, avatar: null });
      setError(null);
    } catch (error) {
      console.error('Error deleting profile picture:', error);
      setError('Error deleting profile picture');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const response = await axios.patch('https://api.syban-datacloud.com/accounts/user/update/', {
        first_name: firstName,
        last_name: lastName,
        role: role,
      }, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setUser({ ...user, first_name: firstName, last_name: lastName, role: role });
      setError(null);
    } catch (error) {
      console.error('Error updating user information:', error);
      setError('Error updating user information');
    } finally {
      setUpdating(false);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await axios.post('https://api.syban-datacloud.com/auth/users/reset_password/', { email });
      setError(null);
      setForgotDialogOpen(false);
    } catch (error) {
      console.error('Error sending reset password email:', error);
      setError('Error sending reset password email');
    }
  };

  const handleResetPassword = async () => {
    try {
      await axios.post('https://api.syban-datacloud.com/auth/users/reset_password_confirm/', { key: resetKey, password });
      setError(null);
      setResetDialogOpen(false);
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Error resetting password');
    }
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!user || error) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h6" color="error">
            {error || 'Error loading user data'}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="1500px" sx={{ direction: direction,  overflowX: 'hidden'  }}>
      <Box
        sx={{
          position: 'relative',
          background: 'linear-gradient(to right, rgb(224, 241, 255), rgb(212, 232, 217)), url("/your-background-image.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 4,
        }}
      >
        <Avatar
          sx={{
            width: 160,
            height: 160,
            border: '4px solid #AEBFAE',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
          }}
          src={preview || user.avatar || '/default-avatar.png'}
          alt={user.username}
        />
        <Typography
          variant="h4"
          sx={{
            position: 'absolute',
            color: '#000',
            textAlign: 'center',
            fontWeight: 'bold',
            bottom: '20px',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
          }}
        >
          {user.username}
        </Typography>
      </Box>
      <Paper elevation={6} sx={{ p: 4, mt: 4, textAlign: 'center', borderRadius: 2 }}>

       
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="upload-avatar"
          type="file"
          onChange={handleUpload}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <label htmlFor="upload-avatar">
            <Button
              variant="contained"
              component="span"
              startIcon={<PhotoCamera />}
              disabled={uploading}
              sx={{ mx: 1, bgcolor: '#AEBFAE', color: '#000', '&:hover': { bgcolor: '#85A785' } }}
            >
              {t('Upload')}
            </Button>
          </label>
          {user.avatar && (
            <IconButton
              color="secondary"
              onClick={handleDelete}
              disabled={uploading}
              sx={{ mx: 1, bgcolor: '#C62828', color: '#fff', '&:hover': { bgcolor: '#d32f2f' } }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>


        {user.avatar && (
          <IconButton
            color="secondary"
            onClick={handleDelete}
            disabled={uploading}
            sx={{ mx: 1, color: '#fff' }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
          {user.username}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          {user.email}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>{t('Details')}:</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                {t('ID')}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {user.id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                {t('First Name')}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {user.first_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                {t('Last Name')}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {user.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                {t('Role')}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {user.role}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box component="form" onSubmit={handleUpdate} sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>{t('Update Information')}:</Typography>
          <TextField
            fullWidth
            variant="outlined"
            label={t('First Name')}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label={t('Last Name')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label={t('Role')}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={updating}
            fullWidth
            sx={{ bgcolor: '#AEBFAE', color: '#fff', '&:hover': { bgcolor: '#648364' } }}
          >
            {updating ? <CircularProgress size={24} /> : t('Update')}
          </Button>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            onClick={() => setForgotDialogOpen(true)}
            sx={{ mr: 2, borderColor: '#476247', color: '#476247', '&:hover': { borderColor: '#648364', color: '#648364' } }}
          >
            {t('Forgot Password')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setResetDialogOpen(true)}
            sx={{ borderColor: '#476247', color: '#476247', '&:hover': { borderColor: '#648364', color: '#648364' } }}
          >
            {t('Reset Password')}
          </Button>
        </Box>
      </Paper>

      {/* Forgot Password Dialog */}
      <Dialog open={forgotDialogOpen} onClose={() => setForgotDialogOpen(false)}>
        <DialogTitle>{t('Forgot Password')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Enter your email address to receive a password reset link.')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t('Email Address')}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setForgotDialogOpen(false)} sx={{ color: "#4F9476" }}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleForgotPassword} sx={{ color: "#4F9476" }}>
            {t('Send')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reset Password Dialog */}
      <Dialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)}>
        <DialogTitle>{t('Reset Password')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Enter the reset key you received and your new password.')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t('Reset Key')}
            fullWidth
            value={resetKey}
            onChange={(e) => setResetKey(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label={t('New Password')}
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResetDialogOpen(false)} sx={{ color: "#4F9476" }}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleResetPassword} sx={{ color: "#4F9476" }}>
            {t('Reset')}
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </Container>
  );
};

export default UserProfile;