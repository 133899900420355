import axios from 'axios';

const API_BASE_URL = 'https://api.syban-datacloud.com/auth/'; // back end base URL

const authApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Login User
export const loginUser = async (email, password) => {
  try {
    const response = await authApi.post('token/login', { email, password });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Register User
export const registerUser = async (username, email, password, re_password, first_name, last_name, role) => {
  try {
    const response = await authApi.post('users/', {
      username,
      email,
      password,
      re_password,
      first_name,
      last_name,
      role
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Logout User
export const logoutUser = async (token) => {
  try {
    await authApi.post('token/logout/', null, {
      headers: {
        'Authorization': `Token ${token}`
      }
    });
    console.log('Logout successful');
    console.log(token)
    window.location.href = '/login'; 
  } catch (error) {
    throw error.response.data;
  }
};
