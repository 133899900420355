import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextareaAutosize,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  FormControlLabel,
  IconButton,
  AppBar,
  Toolbar,
  Snackbar,
  Alert
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import chart from "homepage/assets/dashboard.png";
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#99BA95',
    },
    secondary: {
      main: '#FF6F61', 
    },
    background: {
      default: '#F9FAFB',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    h4: {
      fontFamily: 'Inter, Roboto, sans-serif',
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'Inter, Roboto, sans-serif',
      fontSize: '1.1rem',
      fontWeight: 400,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          padding: '24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '12px 24px',
          transition: 'background-color 0.3s ease',
          color:'#FFFFFF',
          '&:hover': {
            backgroundColor: '#5F875B',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            transition: 'box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
    },
  },
});

const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '40vh',
  padding: '2rem',
  margin:'2rem',
  borderRadius:'20px',
  background: 'linear-gradient(to right, #a1c4fd, #c2e9fb)',
  boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
  marginBottom: '3rem',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
    height: 'auto',
  },
}));

const HeroText = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 'bold',
  color: '#fff',
  marginBottom: '1.5rem',
  textShadow: '4px 4px 16px rgba(0, 0, 0, 0.3)',
  animation: 'fadeIn 1.2s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
}));

const HeroSubText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#000',
  fontWeight: '500',
  marginBottom: '2rem',
  maxWidth: '800px',
  textShadow: '3px 3px 10px rgba(0, 0, 0, 0.3)',
  animation: 'slideIn 1.5s ease-in-out',
  '@keyframes slideIn': {
    from: { transform: 'translateX(-50px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
    margin: '0 1rem',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  padding: '0.85rem 2rem',
  fontSize: '1rem',
  backgroundColor: '#ff9800',
  borderRadius: '20px',
  color: '#fff',
  fontWeight: '700',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#ff6f00',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.3)',
    transform: 'translateY(-3px)',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
  },
}));

const HeroImageBox = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '70%',
    borderRadius: '1rem',
    transform: 'scale(1)',
    transition: 'transform 0.5s ease', // Smooth scaling animation
    '&:hover': {
      transform: 'scale(1.05)', // Slight zoom on hover for interactivity
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: '1.5rem',
  },
}));


const FormGenerator = () => {
  const { t, i18n } = useTranslation();
  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';
  const navigate = useNavigate();


  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    ref: '',
    name_en: '',
    name_fa: '',
    logo: ''
  });

  const [formStructData, setFormStructData] = useState({
    form_struct_id: '',
    form_struct_group: '',
    title: '',
    logo_url: '',
    form_description: '',
    language: '',
    pdf_url: '',
    data: []
  });

  const [status, setStatus] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormStructChange = (e) => {
    const { name, value } = e.target;
    setFormStructData({
      ...formStructData,
      [name]: value
    });
  };

  const handleFieldChange = (sectionIndex, fieldIndex, e) => {
    const { name, value } = e.target;
    const newFormStructData = { ...formStructData };
    newFormStructData.data[sectionIndex].fields[fieldIndex][name] = value;
    setFormStructData(newFormStructData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting...');

    try {
      const response = await axios.post(
        'https://api.syban-datacloud.com/forms/define_form/new_group',
        {
          ref: formData.ref,
          name: {
            en: formData.name_en,
            fa: formData.name_fa
          },
          logo: formData.logo
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setStatus('Form group created successfully!');
      setSnackbar({ open: true, message: 'Form group created successfully!', severity: 'success' });
      console.log(response.data);
    } catch (error) {
      setStatus('Error creating form group.');
      setSnackbar({ open: true, message: 'Error creating form group.', severity: 'error' });
      console.error(error);
    }
  };

  const handleFormStructSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting form structure...');

    try {
      const response = await axios.post(
        'https://api.syban-datacloud.com/forms/define_form/new_form',
        formStructData,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setStatus('Form structure uploaded successfully!');
      setSnackbar({ open: true, message: 'Form structure uploaded successfully!', severity: 'success' });
      console.log(response.data);
    } catch (error) {
      setStatus('Error uploading form structure.');
      setSnackbar({ open: true, message: 'Error uploading form structure.', severity: 'error' });
      console.error(error);
    }
  };

  const renderField = (field, sectionIndex, fieldIndex) => {
    const { field_type, field_name, choices, value, mandatory, repeatable } = field;

    return (
      <Grid container spacing={2} padding={5}  key={fieldIndex}>
        <Grid item xs={12} sm={4}>
          <TextField
            label={t("Field Name")}
            name="field_name"
            value={field_name}
            onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel></InputLabel>
            <Select
              name="field_type"
              value={field_type}
              onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, e)}
            >
              <MenuItem value="SINGLE_SELECT">{t("Single Select")}</MenuItem>
              <MenuItem value="DATE">{t("Date")}</MenuItem>
              <MenuItem value="YES_OR_NO">{t("Yes/No")}</MenuItem>
              <MenuItem value="NUMERIC_FLOAT">{t("Numeric Float")}</MenuItem>
              <MenuItem value="NUMERIC_POSITIVE_INTEGER">{t("Numeric Positive Integer")}</MenuItem>
              <MenuItem value="SMALL_TEXT_BOX">{t("Small Text Box")}</MenuItem>
              <MenuItem value="LARGE_TEXT_BOX">{t("Large Text Box")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {field_type === 'SINGLE_SELECT' && (
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("Choices (comma separated)")}
              name="choices"
              value={choices.join(', ')}
              onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, { target: { name: 'choices', value: e.target.value.split(', ') } })}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={mandatory}
                onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, { target: { name: 'mandatory', value: e.target.checked } })}
                name="mandatory"
                style={{color:"#ADD1C2"}}
              />
            }
            label={t("Mandatory")}
          />
          <FormControlLabel
            control={
              <Switch
                checked={repeatable}
                onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, { target: { name: 'repeatable', value: e.target.checked } })}
                name="repeatable"
                color="primary"
              />
            }
            label={t("Repeatable")}
          />
        </Grid>
        {field_type === 'LARGE_TEXT_BOX' ? (
          <Grid item xs={12}>
            <TextareaAutosize
              minRows={4}
              name="value"
              value={value}
              onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, e)}
              style={{ width: '100%' }}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <TextField
              type={field_type === 'NUMERIC_FLOAT' || field_type === 'NUMERIC_POSITIVE_INTEGER' ? 'number' : 'text'}
              name="value"
              value={value}
              onChange={(e) => handleFieldChange(sectionIndex, fieldIndex, e)}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const addField = (sectionIndex) => {
    const newField = {
      field_name: '',
      field_type: 'SMALL_TEXT_BOX',
      choices: [],
      value: '',
      mandatory: false,
      repeatable: false
    };
    const newFormStructData = { ...formStructData };
    newFormStructData.data[sectionIndex].fields.push(newField);
    setFormStructData(newFormStructData);
  };

  const addSection = () => {
    const newSection = {
      section_name: '',
      fields: []
    };
    setFormStructData({
      ...formStructData,
      data: [...formStructData.data, newSection]
    });
  };

  const handleSectionNameChange = (sectionIndex, e) => {
    const { value } = e.target;
    const newFormStructData = { ...formStructData };
    newFormStructData.data[sectionIndex].section_name = value;
    setFormStructData(newFormStructData);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className={direction}>
      <HeroSection py={2}>
        <HeroImageBox >
            <img src={chart} alt="Hero" />
          </HeroImageBox>
          <Box>
            <HeroText>{t("Effortless Form Creation, Tailored to Your Needs")}</HeroText>
            <HeroSubText>{t("Create dynamic, fully customizable forms in just a few clicks. Empower your workflow with a seamless and intuitive form generator designed to save time and maximize productivity. Build, edit, and manage forms with ease—no coding required")}</HeroSubText>
           
          </Box>
          
        </HeroSection>
      <ThemeProvider theme={theme}>
        <Container>
          <Card variant="outlined" sx={{ mb: 3 }}>
            <CardHeader title={t('Create Form Group')} />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Reference')}
                      name="ref"
                      value={formData.ref}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Name (English)')}
                      name="name_en"
                      value={formData.name_en}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Name (Farsi)')}
                      name="name_fa"
                      value={formData.name_fa}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Logo URL')}
                      name="logo"
                      value={formData.logo}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      {t('Create Form Group')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>

          <Card variant="outlined">
            <CardHeader title={t('Create Form Structure')} />
            <CardContent>
              <form onSubmit={handleFormStructSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Form Structure ID')}
                      name="form_struct_id"
                      value={formStructData.form_struct_id}
                      onChange={handleFormStructChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Form Structure Group')}
                      name="form_struct_group"
                      value={formStructData.form_struct_group}
                      onChange={handleFormStructChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Title')}
                      name="title"
                      value={formStructData.title}
                      onChange={handleFormStructChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Logo URL')}
                      name="logo_url"
                      value={formStructData.logo_url}
                      onChange={handleFormStructChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Form Description')}
                      name="form_description"
                      value={formStructData.form_description}
                      onChange={handleFormStructChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('Language')}
                      name="language"
                      value={formStructData.language}
                      onChange={handleFormStructChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t('PDF URL')}
                      name="pdf_url"
                      value={formStructData.pdf_url}
                      onChange={handleFormStructChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="h6">{t('Form Sections')}</Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={addSection}
                        style={{color:"#000", background:"#ADD1C2"}}
                      >
                        {t('Add Section')}
                      </Button>
                    </Box>
                    {formStructData.data.map((section, sectionIndex) => (
                      <Card variant="outlined" sx={{ my: 2 }} key={sectionIndex}>
                        <CardContent>
                          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="subtitle1">{t('Section')} {sectionIndex + 1}</Typography>
                            <IconButton
                              color="error"
                              onClick={() => {
                                const newFormStructData = { ...formStructData };
                                newFormStructData.data.splice(sectionIndex, 1);
                                setFormStructData(newFormStructData);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Box>
                          <Divider sx={{ mb: 2 }} />
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField
                                label={t('Section Name')}
                                value={section.section_name}
                                onChange={(e) => handleSectionNameChange(sectionIndex, e)}
                                fullWidth
                              />
                            </Grid>
                            {section.fields.map((field, fieldIndex) => renderField(field, sectionIndex, fieldIndex))}
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                style={{color:"#000"}}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => addField(sectionIndex)}
                                fullWidth
                              >
                                {t('Add Field')}
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      {t('Create Form Structure')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>

          <Box mt={3}>
            <Typography variant="body1" color="error">
              {status}
            </Typography>
          </Box>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default FormGenerator;
