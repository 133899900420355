import React, { useState, useEffect } from 'react';
import './Company.css';
import Header from 'components/Header';
import Footer from 'homepage/Footer/Footer';
import { FaPhone, FaMapMarkerAlt, FaLink, FaEdit, FaUpload, FaTrash, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

const API_URL = "https://api.syban-datacloud.com/accounts/company/";
const token = localStorage.getItem("token");
const LOGO_API_URL = "https://api.syban-datacloud.com/accounts/company/logo/";

const Company = () => {
  const { t } = useTranslation();
  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  const [companyProfile, setCompanyProfile] = useState(null);
  const [logo, setLogo] = useState(null);
  const [newProfile, setNewProfile] = useState({
    company_name: "",
    address: [""],
    phone_number: [""],
    website_url: ""
  });

  useEffect(() => {
    fetchProfile();
    fetchLogo();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await fetch(API_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
        }
      });
      if (response.ok) {
        const data = await response.json();
        setCompanyProfile(data);
        setNewProfile({
          company_name: data.company_name || "",
          address: data.address || [""],
          phone_number: data.phone_number || [""],
          website_url: data.website_url || ""
        });
      } else {
        console.error('Error fetching company profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching company profile:', error);
    }
  };

  const fetchLogo = async () => {
    try {
      const response = await fetch(LOGO_API_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
        }
      });
      if (response.ok) {
        const data = await response.text();  // assuming the response is a plain text URL
        console.log(data);
        setLogo(data);  // Set the URL string directly to the logo state
      } else {
        console.error('Error fetching logo:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  };
  
  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL, {
        method: 'PUT',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newProfile)
      });
      if (response.ok) {
        const data = await response.json();
        setCompanyProfile(newProfile);
      } else {
        console.error('Error updating profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleLogoSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('Logo', e.target.logo.files[0]);
    formData.append('Profile-ID', 'C-1717590659-QA2T');

    try {
      const response = await fetch(LOGO_API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
        },
        body: formData
      });
      if (response.ok) {
        const data = await response.json();
        setLogo(data);
      } else {
        console.error('Error uploading logo:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading logo:', error);
    }
  };

  const deleteProfile = async () => {
    try {
      const response = await fetch(API_URL, {
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${token}`,
        }
      });
      if (response.ok) {
        setCompanyProfile(null);
        setLogo(null);
      } else {
        console.error('Error deleting profile:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  const deleteLogo = async () => {
    try {
      const response = await fetch(LOGO_API_URL, {
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${token}`,
        }
      });
      if (response.ok) {
        setLogo(null);
      } else {
        console.error('Error deleting logo:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting logo:', error);
    }
  };

  const isRtl = i18n.language === 'fa' ? 'rtl' : 'ltr';
  

  return (
    <>
    <div className={`company-page ${isRtl}`}>
      {/* Profile Header Section */}
      <div className="profile-header">
        <div className="cover-photo">
          {/* Upload Cover Photo */}
        </div>
        <div className="profile-info">
          <div className="logo-container">
            <img 
              src="https://s3.syban-datacloud.com/company/default_logo.PNG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=EowKdBQWG0YrDQ30mgqb%2F20240905%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240905T212147Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=0cec6d611393b15f79c21dd0326c8658ad1506c7981f8f05f225e19d03cd4260" 
              alt={t('companyLogo')} 
              className="company-logo" 
            />
          </div>
          <h1 className="company-name">{companyProfile?.company_name || t('companyName')}</h1>
        </div>
      </div>
  
      {/* Body Section */}
      <div className="profile-body">
        {/* Profile Details */}
        <div className={`profile-card ${isRtl}`}>
          <h2>{t('companyDetails')}</h2>
          <p className="company-website">
            <FaLink /> <a href={companyProfile?.website_url} target="_blank" rel="noopener noreferrer">{companyProfile?.website_url}</a>
          </p>
          <p className={`company-address  ${isRtl}`}><FaMapMarkerAlt /> {companyProfile?.address?.join(', ')}</p>
          <p><FaPhone /> {companyProfile?.phone_number?.join(', ')}</p>
          <div className="button-container">
            <button onClick={deleteProfile} className="delete-button">
              <FaTrash /> {t('deleteProfile')}
            </button>
          </div>
        </div>
  
        {/* Update Profile Form */}
        <div className={`update-profile-card ${isRtl}`}>
          <h2>{t('updateProfile')}</h2>
          <form onSubmit={handleProfileSubmit} className="form">
            <label>{t('companyName')}</label>
            <input
              type="text"
              placeholder={t('companyName')}
              value={newProfile.company_name}
              onChange={(e) => setNewProfile({ ...newProfile, company_name: e.target.value })}
            />
            <label>{t('address')}</label>
            <input
              type="text"
              placeholder={t('address')}
              value={newProfile.address.join(', ')}
              onChange={(e) => setNewProfile({ ...newProfile, address: e.target.value.split(',') })}
            />
            <label>{t('phoneNumbers')}</label>
            <input
              type="text"
              placeholder={t('phoneNumbers')}
              value={newProfile.phone_number.join(', ')}
              onChange={(e) => setNewProfile({ ...newProfile, phone_number: e.target.value.split(',') })}
            />
            <label>{t('websiteURL')}</label>
            <input
              type="text"
              placeholder={t('websiteURL')}
              value={newProfile.website_url}
              onChange={(e) => setNewProfile({ ...newProfile, website_url: e.target.value })}
            />
            <button type="submit" className="submit-button"><FaEdit /> {t('updateProfile')}</button>
          </form>
        </div>
  
        {/* Logo Upload Section */}
        <div className="logo-upload">
          <h2>{t('uploadLogo')}</h2>
          <form onSubmit={handleLogoSubmit} className="logo-form">
            <input type="file" name="logo" />
            <button type="submit" className="upload-button"><FaUpload /> {t('uploadLogo')}</button>
          </form>
          {logo && (
            <button onClick={deleteLogo} className="delete-button">
              <FaTrash /> {t('deleteLogo')}
            </button>
          )}
        </div>
      </div>
      
    </div>
    <Footer/>
    </>
  );
};

export default Company;