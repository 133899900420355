import React, { useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Article from './Article';
// import NewsGrid from './NewsGrid';
import { useTranslation } from "react-i18next";
import FlexBetween from 'components/FlexBetween';
import { Menu, MenuItem } from '@mui/material';
import Footer from 'homepage/Footer/Footer';
import { FaArrowUp } from 'react-icons/fa';
import Pagination from '@mui/material/Pagination';
import SocialShare from './SocialShare';
import NewsItem from './NewsItem';
import Header from 'homepage/Header/Header';

// const NewsGridContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
//   gap: 30px;
//   padding: 40px;
//   background-color: #FFFFFF;
//   border-radius: 10px
// `;


const NewsItemContainer = styled.div`
display: flex;
flex-direction: column;
margin-left:25rem;
justify-content: right;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width:50%
`;
const ArticleItemContainer = styled.div`
display: flex;
flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width:145%;
 
`;


const BlogContainer = styled.div`
  display: flex;
 flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  padding: 20px;
  display: flex;
flex-direction: column;
  gap: 40px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 20px; /* Adjust the gap as needed */
`;
const BottomContainer = styled.div`
margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px; /* Adjust the gap as needed */
`; 


const Section = styled.section`
  margin-bottom: 40px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const NewsSectionTitle = styled.h2`
  font-size: 32px;
  color: white;
  margin-bottom: 20px;
  background-color: #98CAA1;
  border-radius: 8px;
  padding:15px 20px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Darker and more opaque */
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.3); /* Darker and larger blur */
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const ArticleSectionTitle = styled.h2`
  font-size: 35px;
  display: flex;
  justify-content:center;
  color: #98CAA1;
  margin-bottom: 20px;
  margin-right: 30px;
  padding:15px 20px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Darker and more opaque */
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2); /* Darker and larger blur */
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;


const Grid = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 20px;
@media (max-width: 768px) {
  grid-template-columns: 1fr;
}
@media (min-width: 1024px) {
  grid-template-columns: repeat(3, 1fr);
}
`;

const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr; /* Single column */
  gap: 30px;
  margin-bottom:50px
  width:100%;
  padding: 0.5rem
`;
const NewsCard = styled.div`
padding: 0 18px;

`;
const ArticleGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
justify-content: center;
width: 100%;
height: 700px; /* Fixed height */
gap: 35px;
padding: 0 15px;
`;
const ArticleCard = styled.div`
width: 100%;
box-sizing: border-box; /* Ensure padding and border are included in the width and height */
height: 600px; /* Take full height of the grid cell */
display: flex;
flex-direction: column;
`;

const VideoGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
justify-content: center;
width: 100%;
gap: 35px;
padding: 2rem;


`;


const VideoCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const VideoTitle = styled.h3`
font-size: 20px;
color: #715F4C;
margin-bottom: 10px;
text-align: center;
margin-top:-1rem


display: flex;
justify-content:left;
padding-bottom: 15px;

text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Darker and more opaque */
box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2); /* Darker and larger blur */
@media (max-width: 768px) {
  font-size: 20px;
}

`;
const VideoSectionTitle = styled.h2`
  font-size: 35px;
  display: flex;
  justify-content:left;
  color: #98CAA1;
  margin-bottom: 20px;
  margin-right: 30px;
  padding:15px 20px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Darker and more opaque */
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2); /* Darker and larger blur */
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;


const VideoDescription = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #333;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.3s linear;
  }
`;

const lightTheme = {
  body: '#FFF',
  text: '#333'
};

const darkTheme = {
  body: '#333',
  text: '#FFF'
};

const articles = [
  {
    image: 'https://banner2.cleanpng.com/20240226/ouu/transparent-business-meeting-positive-business-meeting-with-energetic-1710864880460.webp',
    title: 'The Importance of Waste Management',
    content: 'Waste management is an essential aspect of environmental conservation...'
  },
  {
    image: 'https://banner2.cleanpng.com/20240315/aoc/transparent-graduation-group-working-on-laptop-with-smiles-1710836458297.webp',
    title: 'Innovative Waste Management Technologies',
    content: 'New technologies are emerging in the waste management sector...'
  },
  {
    image: 'https://banner2.cleanpng.com/20240319/osp/transparent-classroom-desk-laptop-workspace-office-professional-organized-workspace-with-laptop-1710897472379.webp',
    title: 'Recycling Best Practices, join us in this journey',
    content: 'Recycling helps reduce waste and conserve resources...'
  },
  {
    image: 'https://banner2.cleanpng.com/lnd/20240628/pst/a7yrlqjz5.webp',
    title: 'Recycling Best Practices',
    content: 'Recycling helps reduce waste and conserve resources...'
  },
];

const newsItems = [
  {
    headline: 'New Regulations on Waste Disposal Announced',
    content: 'The government has introduced new regulations aimed at improving waste disposal systems...'
  },
  {
    headline: 'Waste Management Conference 2024',
    content: 'Experts gather to discuss the future of waste management at the annual conference...'
  },
  {
    headline: 'Community Initiatives in Waste Reduction',
    content: 'Local communities are taking action to reduce waste through various initiatives...'
  },
  {
    headline: 'Technological Advances in Recycling',
    content: 'Innovations in recycling technology are making the process more efficient...'
  },
  {
    headline: 'Global Waste Management Trends',
    content: 'A look at how different countries are handling waste management challenges...'
  },
  {
    headline: 'Impact of Waste on Climate Change',
    content: 'An in-depth analysis of how waste management practices affect global warming...'
  },
  {
    headline: 'The Economics of Recycling',
    content: 'Exploring the financial benefits and challenges of recycling programs...'
  },
  {
    headline: 'Educational Programs on Waste Management',
    content: 'Schools and universities are integrating waste management into their curriculums...'
  },
];

const videos = [
  {
    title: 'How Recycling Works',
    description: 'An informative video explaining the recycling process and its benefits.',
    url: 'https://www.youtube.com/embed/1Q1Dc2JlV7Y'
  },
  {
    title: 'The Future of Waste Management',
    description: 'A discussion on emerging technologies and innovations in waste management.',
    url: 'https://www.youtube.com/embed/2l8LO1fC-6Y'
  },
  {
    title: 'Community Recycling Initiatives',
    description: 'How local communities are contributing to recycling efforts.',
    url: 'https://www.youtube.com/embed/3n3D7F6U7N8'
  },
  {
    title: 'How Recycling Works2',
    description: 'An informative video explaining the recycling process and its benefits.',
    url: 'https://www.youtube.com/embed/1Q1Dc2JlV7Y'
  },
 
];

const Blog = () => {
  const [showMore, setShowMore] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const [currentPageArticles, setCurrentPageArticles] = useState(1);
  const [currentPageNews, setCurrentPageNews] = useState(1);
  const [currentPageVideos, setCurrentPageVideos] = useState(1);

  const [searchTerm, setSearchTerm] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredArticles = articles.filter(article =>
    article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    article.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredNewsItems = newsItems.filter(news =>
    news.headline.toLowerCase().includes(searchTerm.toLowerCase()) ||
    news.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const handleToggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  window.addEventListener('scroll', handleScroll);

  const itemsPerPage = 3;
  const totalPagesArticles = Math.ceil(filteredArticles.length / itemsPerPage);
  const displayedArticles = filteredArticles.slice((currentPageArticles - 1) * itemsPerPage, currentPageArticles * itemsPerPage);

  const itemsPerPageNews = 3
  const totalPagesNews = Math.ceil(filteredNewsItems.length / itemsPerPageNews);
  const displayedNewsItems = filteredNewsItems.slice((currentPageNews - 1) * itemsPerPageNews, currentPageNews * itemsPerPageNews);

  const itemsPerPageVideos = 4;
  const totalPagesVideos = Math.ceil(videos.length / itemsPerPageVideos);
  const displayedVideos = videos.slice((currentPageVideos - 1) * itemsPerPageVideos, currentPageVideos * itemsPerPageVideos);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <GlobalStyle />
      <BlogContainer>
        <div>
          <Header/>
        </div>
        <MainContent>
          <TopContainer >
            <ArticleItemContainer>
              <ArticleSectionTitle>{t('Articles')}</ArticleSectionTitle>
              <ArticleGrid>
                {displayedArticles.map((article, index) => (
                  <ArticleCard key={index}>
                    <Article {...article} />
                    <SocialShare title={article.title} />
                  </ArticleCard>
                ))}
              </ArticleGrid>
              <Pagination
                count={totalPagesArticles}
                page={currentPageArticles}
                onChange={(event, value) => setCurrentPageArticles(value)}
              />
            </ArticleItemContainer>
            <NewsItemContainer>
              <NewsSectionTitle>{t('News')}</NewsSectionTitle>
              < NewsGrid>
                {displayedNewsItems.map((news, index) => (
                  <NewsCard key={index}>
                    <NewsItem {...news} />
                  </NewsCard>
                ))}
              </ NewsGrid>
              <Pagination
                count={totalPagesNews}
                page={currentPageNews}
                onChange={(event, value) => setCurrentPageNews(value)}
              />
            </NewsItemContainer>
          </TopContainer>

          <BottomContainer>
            <Section>
              <VideoSectionTitle>{t('Featured Videos')}</VideoSectionTitle>
              <VideoGrid>
                {videos.map((video, index) => (
                  <VideoCard key={index}>
                    <VideoTitle>{video.title}</VideoTitle>
                    <VideoDescription>{video.description}</VideoDescription>
                    <iframe
                      width="100%"
                      height="315"
                      src={video.url}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </VideoCard>
                ))}
              </VideoGrid>
              <Pagination
                count={totalPagesVideos}
                page={currentPageVideos}
                onChange={(event, value) => setCurrentPageVideos(value)}
              />
            </Section>
          </BottomContainer>

        </MainContent>

        <Footer />
        <ScrollToTopButton
          show={showScrollButton}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <FaArrowUp />
        </ScrollToTopButton>
      </BlogContainer>
    </ThemeProvider>
  );
}

export default Blog;
