import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ReceiptLongOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  ExitToAppOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { logoutUser } from "../api/authApi";

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      if (!token) throw new Error('Token not found');

      await logoutUser(token); // Pass token to logoutUser function
      localStorage.removeItem('token'); // Clear token from localStorage after logout
      navigate("/home");
    } catch (error) {
      console.error("Logout error:", error);
      navigate("/home");
    }
  };

  const navItems = [
    {
      text: "Dashboard",
      icon: <HomeOutlined />,
    },
    {
      text: "Client Features",
      icon: null,
    },
    {
      text: "data-dashboard",
      icon: <CalendarMonthOutlined />,
    },
    {
      text: "formgenerator",
      icon: <CalendarMonthOutlined />

    },
    {
      text: "formsgroups",
      icon: <ReceiptLongOutlined />,
    },
    {
      text: "Users Access",
      icon: null,
    },

    {
      text: "MyForms",
      icon: <TodayOutlined />,
    },
    {
      text: "user-profile",
      icon: <AdminPanelSettingsOutlined />,
    },

    {
      text: "Admin Access",
      icon: null,
    },

    {
      text: "Inbox",
      icon: <AdminPanelSettingsOutlined />,
    },
    {
      text: "user-management",
      icon: <AdminPanelSettingsOutlined />,
    },
    {
      text: "company",
      icon: <AdminPanelSettingsOutlined />,
    },

  ];

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[300],
              backgroundColor: "#F0F0F0",
              boxSixing: "border-box",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary[400]}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <img src="/logo.png" alt="Logo" style={{ width: "130px", height: "auto" }} />
                </Box>

                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text} variant="h6" fontWeight="bold" sx={{ m: "2.25rem 0 1rem 3rem", fontSize: "1rem" }}>
                      {t(text)}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[100]
                            : "transparent",
                        color:
                          active === lcText
                            ? "#6B8A7A"
                            : "6B8A7A",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? "#6B8A7A"
                              : theme.palette.secondary[400],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={t(text)} sx={{ fontSize: "1.2rem" }} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>

          </Box>

          <Box position="absolute" bottom="2rem">

            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">

              <Box textAlign="left">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize="1.2rem"
                  sx={{ color: theme.palette.secondary[400] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="1rem"
                  sx={{ color: theme.palette.secondary[400] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <Button
                onClick={handleLogout}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "0.5rem",
                  color: "#FD0000",
                }}
              >
                {t("Logout")}
                <ExitToAppOutlined />
              </Button>

            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  );

};

export default Sidebar;