import React, { useEffect, useState, useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Footer from 'homepage/Footer/Footer';
import {
  AppBar, Toolbar, Typography, Grid, TextField, MenuItem, Select, FormControl, InputLabel, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Box, TablePagination, IconButton, InputAdornment
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import i18n from 'i18next';
import { t } from 'i18next';
import Header from 'components/Header';

// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#004d40',
    },
  },
  typography: {
    h6: {
      fontWeight: 'bold',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '20px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const SafetyDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardError, setDashboardError] = useState(null);
  const [timeInterval, setTimeInterval] = useState('last_month');
  const [searchInput, setSearchInput] = useState('');
  const token = localStorage.getItem("token");

  const fetchDashboardData = async () => {
    try {
      const response = await fetch('https://api.syban-datacloud.com/dashboard/dashboard/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          dashboard_name: 'number_of_incidents',
          time_interval: timeInterval,
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Fetched data:', result);
      setDashboardData(result);
    } catch (error) {
      setDashboardError(error.message);
    } finally {
      setDashboardLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [token, timeInterval]);

  const chartData = useMemo(() => {
    if (!dashboardData || !dashboardData.message) {
      console.warn('No data available for chart');
      return { labels: [], datasets: [] };
    }

    const labels = dashboardData.message;
    const dataValues = labels.map(() => 0); // Replace with actual data values

    return {
      labels,
      datasets: [
        {
          label: t('Number of Incidents'),
          data: dataValues,
          backgroundColor: 'rgba(33, 150, 243, 0.6)',
          borderColor: '#00796b',
          borderWidth: 1,
          borderRadius: 5,
        }
      ]
    };
  }, [dashboardData]);

  const columns = useMemo(() => [
    {
      Header: t('Date'),
      accessor: 'date',
    },
    {
      Header: t('Incident Count'),
      accessor: 'count',
    },
  ], []);

  const data = useMemo(() => {
    if (!dashboardData || !dashboardData.message) {
      console.warn('No data available for table');
      return [];
    }

    const dates = dashboardData.message;
    const counts = dates.map(() => 0); // Replace with actual data values

    return dates.map((date, index) => ({
      date,
      count: counts[index]
    }));
  }, [dashboardData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    pageOptions,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useGlobalFilter,
    usePagination
  );

  const handleSearch = (e) => {
    const value = e.target.value || '';
    setSearchInput(value);
    setGlobalFilter(value);
  };

  if (dashboardLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (dashboardError) {
    return <div>Error: {dashboardError}</div>;
  }

  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  return (
    <div>
      <Box sx={{ px: 2, width: '100%' }}>
        <Header title={t("Health and Safety dashboard")} />
      </Box>
    <ThemeProvider theme={theme}>
      

      <Grid container spacing={4} style={{ marginTop: '20px', padding: '20px', display: 'flex', alignItems: 'stretch', direction:direction }}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>{t("timeInterval")}</InputLabel>
            <Select
              value={timeInterval}
              onChange={(e) => setTimeInterval(e.target.value)}
              label={t("timeInterval")}
            >
              <MenuItem value="last_day">{t("lastDay")}</MenuItem>
              <MenuItem value="last_week">{t("lastWeek")}</MenuItem>
              <MenuItem value="last_month">{t("lastMonth")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ padding: '20px', display: 'flex', alignItems: 'stretch' }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={4} sx={{ borderRadius: 2, p: 3, height: '100%' }}>
            <Chart type="bar" data={chartData} options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: t('Number of Incidents'),
                },
              },
              maintainAspectRatio: false, // This ensures the chart fills its container
            }} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={4} sx={{ borderRadius: 2, p: 3, height: '100%' }}>
            <TextField
              value={searchInput}
              onChange={handleSearch}
              variant="outlined"
              fullWidth
      
              placeholder={t('Search')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3, direction:direction }}
            />
            <TableContainer>
              <Table {...getTableProps()}  sx={{ direction:direction }}>
                <TableHead sx={{ direction:direction }}>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}  sx={{ direction:direction }}>
                      {headerGroup.headers.map(column => (
                        <TableCell
                          {...column.getHeaderProps()}
                          sx={{ fontWeight: 'bold', backgroundColor: '#7AAB85', color: '#FFF', p: 2 , direction:direction }}
                        >
                          {t(`${column.render('Header')}`)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()} hover>
                        {row.cells.map(cell => (
                          <TableCell {...cell.getCellProps()} sx={{ p: 2, color: '#333' }}>
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={(event, newPage) => {
                if (newPage > pageIndex) {
                  nextPage();
                } else {
                  previousPage();
                }
              }}
              onRowsPerPageChange={(event) => {
                setPageSize(Number(event.target.value));
              }}
              sx={{ mt: 2 }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Footer />
    </ThemeProvider>
    </div>
  );
};

export default SafetyDashboard;