// src/homepage/Blog/SocialShare.jsx
import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const ShareContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const ShareButton = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 1.5em;
`;

const SocialShare = ({ title, url = window.location.href }) => {
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(url);

  return (
    <ShareContainer>
      <ShareButton
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedTitle}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </ShareButton>
      <ShareButton
        href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTwitter />
      </ShareButton>
      <ShareButton
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin />
      </ShareButton>
      <ShareButton
        href={`https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </ShareButton>
    </ShareContainer>
  );
};

export default SocialShare;
