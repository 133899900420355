import React, { useState, useEffect } from 'react';
import './Workflow.css';
import { workflowData } from 'homepage/data/workflowData';
import { useTranslation } from "react-i18next";

const Workflow = () => {
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState(0);
    const tLength = workflowData.length;
    
    // Check if the language is RTL (e.g., Farsi 'fa')
    const isRTL = i18n.language === 'fa'; 

    const handleDotClick = (index) => {
        setSelected(index);
    };

    return (
        <div className={`workflow ${isRTL ? 'rtl' : 'ltr'}`}>
            <div className="left-w">
                <h1>{t('Creating Value Together')}</h1>
                <h3>{t(workflowData[selected].header)}</h3>
                <blockquote>{t(workflowData[selected].review)}</blockquote>
            </div>
            <div className="right-w">
                <img src={workflowData[selected].image} alt={t('Workflow Image')} />
                <div className="dots">
                    {workflowData.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${selected === index ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Workflow;
