import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Typography,
  useMediaQuery,
  Grid,
  Avatar,
} from "@mui/material";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { useTheme } from "@emotion/react";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../homepage/assets/Health and Safety.jpg"
import Formsofx from "formComponents";

const Health_and_safety = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const theme = useTheme();
  const [forms, setForms] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [responseData, setResponseData] = useState("");
  const navigate = useNavigate();

  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await fetch(
          `https://api.syban-datacloud.com/forms/list-forms/health_and_safety/${i18n.language}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch form data");
        }
        const data = await response.json();
        setResponseData(data);
      } catch (error) {
        console.error("Error fetching forms:", error);
      }
    };

    fetchForms();
  }, [i18n.language]);

  const healthForms = forms.filter(
    (form) => form.group === "Health_and_Safety"
  );

  const handleEditClick = async (form_struct_id) => {
    navigate(`/formsofx/${form_struct_id}`);
    // Navigate to Formsofx page with selected form details
  };

  return (
    <Box m="1.5rem 2.5rem" sx={{direction:direction}}>
      <Header
        title={t("healthAndSafety")}
      />
      <Grid container spacing={2} mt="20px">
        {responseData.length > 0 && responseData.map((form, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                backgroundImage: "none",
                backgroundColor: "#F0F0F0",
                borderRadius: "0.55rem",
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontSize: "1.2rem",
                    textAlign: i18n.language === "fa" ? "right" : "left",
                  }}
                >
                  {form.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    textAlign: i18n.language === "fa" ? "right" : "left",
                  }}
                >
                  {form.form_description}
                </Typography>
              </CardContent>
              <CardActions sx={{ marginTop: 'auto' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={() => handleEditClick(form.form_struct_id)}
                  >
                    <FaRegEdit size={20} color="#6B8A7A" />
                  </Button>
                  
                  <Avatar sx={{ width: 55, height: 55 }} alt="Waste Management Logo" src={logo} />
                </Box>
              </CardActions>
              <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                  color: theme.palette.neutral[300],
                }}
              >
                <CardContent>
                  <Typography variant="body2">
                    Form Group: {form.form_struct_group}
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
        ))}
      </Grid>
      
    </Box>
  );
};

export default Health_and_safety;