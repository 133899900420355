import React, { useState } from 'react';
import { FaLock, FaUser } from 'react-icons/fa';
import { loginUser } from '../api/authApi';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import loginImage from '../../src/homepage/assets/login.png';
import Header from 'homepage/Header/Header';
import Footer from 'homepage/Footer/Footer';

const LoginForm = ({ onLogin }) => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userData = await loginUser(email, password);
            if (!userData.auth_token) {
                throw new Error('Token not received');
            }
            localStorage.setItem('token', userData.auth_token);
            onLogin(userData);
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            setError('Invalid email or password');
            setShowAlert(true);
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const styles = {
        wrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            padding: '1rem',
            background: 'linear-gradient(135deg, #f7f7f7, #e3e3e3)',
            fontFamily: '"Poppins", sans-serif',
        },
        formContainer: {
            maxWidth: '450px',
            width: '100%',
            padding: '2rem',
            background: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
        },
        heading: {
            fontSize: '2rem',
            marginBottom: '1rem',
            color: '#333333',
            fontWeight: '600',
            textAlign: 'center',
        },
        inputBox: {
            width: '100%',
            position: 'relative',
            marginBottom: '1rem',
        },
        input: {
            width: '100%',
            padding: '0.75rem 2rem',
            border: '1px solid #ddd',
            borderRadius: '12px',
            fontSize: '1rem',
            backgroundColor: '#fafafa',
            transition: 'border-color 0.3s, background-color 0.3s',
            outline: 'none',
        },
        inputFocus: {
            borderColor: '#007bff',
            backgroundColor: '#ffffff',
        },
        icon: {
            position: 'absolute',
            left: '15px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#aaa',
        },
        button: {
            width: '100%',
            padding: '0.75rem',
            background: '#8fcc87',
            color: '#ffffff',
            fontSize: '1.1rem',
            border: 'none',
            borderRadius: '12px',
            cursor: 'pointer',
            transition: 'background 0.3s ease, transform 0.2s ease',
        },
        buttonHover: {
            background: '#6B8A7A',
            transform: 'translateY(-2px)',
        },
        loginImage: {
            width: '100%',
            maxWidth: '450px',
            marginTop: '1rem',
        },
        alert: {
            position: 'absolute',
            top: '-50px',
            right: '10px',
            padding: '0.75rem 1.5rem',
            background: '#f44336',
            color: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
        },
        closebtn: {
            position: 'absolute',
            top: '5px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '1.25rem',
        },
    };

    return (
        <div>
            <Header />
            <div style={styles.wrapper}>
                <div style={styles.formContainer}>
                    <h1 style={styles.heading}>{t('Login')}</h1>
                    <form onSubmit={handleSubmit}>
                        <div style={styles.inputBox}>
                            <input
                                type="text"
                                placeholder={t('Email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                style={styles.input}
                            />
                            <FaUser style={styles.icon} />
                        </div>
                        <div style={styles.inputBox}>
                            <input
                                type="password"
                                placeholder={t('Password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                style={styles.input}
                            />
                            <FaLock style={styles.icon} />
                        </div>
                        <button
                            type="submit"
                            style={styles.button}
                            onMouseOver={(e) => e.currentTarget.style.background = styles.buttonHover.background}
                            onMouseOut={(e) => e.currentTarget.style.background = styles.button.background}
                        >
                            {t('Login')}
                        </button>
                        <div className="remember-forgot" style={{ marginTop: '1rem', width: '100%', textAlign: 'center' }}>
                            <label>
                                <input type="checkbox" /> {t('Remember me')}
                            </label>
                            <div style={{ marginTop: '0.5rem' }}>
                                <Link to='/forgot-password'>{t('Forgot password?')}</Link>
                            </div>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                            <p>
                                {t("Don't have an account?")}
                                <Link to='/register'>{t('Join us')}</Link>
                            </p>
                        </div>
                    </form>
                    <img src={loginImage} alt={t('Login Illustration')} style={styles.loginImage} />
                </div>
            </div>
            {/* Custom alert */}
            {showAlert && (
                <div style={styles.alert}>
                    <span style={styles.closebtn} onClick={handleAlertClose}>&times;</span>
                    {t(error)}
                </div>
            )}
            <Footer/>
        </div>
    );
};

export default LoginForm;
