import React from 'react';
import { FaMailBulk } from 'react-icons/fa';
import forgot from '../../src/homepage/assets/forgot.png';
import Header from 'homepage/Header/Header';
import Footer from 'homepage/Footer/Footer';

const ForgotPasswordForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle forgot password submission
  };

  const styles = {
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '80vh',
      padding: '1rem',
      background: 'linear-gradient(135deg, #f7f7f7, #e3e3e3)',
      fontFamily: '"Poppins", sans-serif',
    },
    formContainer: {
      maxWidth: '450px',
      width: '100%',
      padding: '2rem',
      background: '#ffffff',
      borderRadius: '16px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },
    heading: {
      fontSize: '2rem',
      marginBottom: '1rem',
      color: '#333333',
      fontWeight: '600',
      textAlign: 'center',
    },
    subheading: {
      fontSize: '1rem',
      marginBottom: '1rem',
      color: '#666666',
      textAlign: 'center',
    },
    inputBox: {
      width: '100%',
      position: 'relative',
      marginBottom: '1rem',
    },
    input: {
      width: '100%',
      padding: '0.75rem 2rem',
      border: '1px solid #ddd',
      borderRadius: '12px',
      fontSize: '1rem',
      backgroundColor: '#fafafa',
      transition: 'border-color 0.3s, background-color 0.3s',
      outline: 'none',
    },
    icon: {
      position: 'absolute',
      left: '15px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#aaa',
    },
    button: {
      width: '100%',
      padding: '0.75rem',
      background: '#8fcc87',
      color: '#ffffff',
      fontSize: '1.1rem',
      border: 'none',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'background 0.3s ease, transform 0.2s ease',
    },
    buttonHover: {
      background: '#6B8A7A',
      transform: 'translateY(-2px)',
    },
    loginImage: {
      width: '100%',
      maxWidth: '450px',
      marginTop: '1rem',
    },
  };

  return (
    <div>
      <Header />
      <div style={styles.wrapper}>
        <div style={styles.formContainer}>
          <h1 style={styles.heading}>Reset Password</h1>
          <span style={styles.subheading}>Enter your Email</span>
          <form onSubmit={handleSubmit}>
            <div style={styles.inputBox}>
              <input
                type="email"
                placeholder="Email"
                required
                style={styles.input}
              />
              <FaMailBulk style={styles.icon} />
            </div>
            <button
              type="submit"
              style={styles.button}
              onMouseOver={(e) => e.currentTarget.style.background = styles.buttonHover.background}
              onMouseOut={(e) => e.currentTarget.style.background = styles.button.background}
            >
              Submit
            </button>
          </form>
          <div className="login-container" style={{ marginTop: '1rem' }}>
            <img src={forgot} alt="Forgot Password Illustration" style={styles.loginImage} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPasswordForm;
