// src/pages/Error500.js
import React from 'react';
import { Link } from 'react-router-dom';
import error from 'assets/4.png';
import { color } from 'framer-motion';

const Error502 = () => {
  return (
    <div style={styles.container}>
      <div style={styles.textSection}>
        <h1 style={styles.title}>Oops!</h1>
        <h2 style={styles.subtitle}> Bad Gateway</h2>
        <div style={styles.messageContainer}>
          <div style={styles.messagePart}>
             <p style={styles.message}>
             • Please try refreshing the page
             </p>

              <span style={styles.message}>
              • OR visit the <Link to="/" style={styles.link}>homepage</Link> and try again later
              </span>
          </div>
            <p  style={styles.apol}>
              We encountered a problem while connecting to the server and appreciate your patience.
            </p> 
        </div>
        <Link to="/" style={styles.link}>Back to Home</Link>
      </div>
      <div style={styles.imageContainer}>
        <img style={styles.image} src={error} alt='error' />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '0 8rem',
  },
  textSection: {
    flex: 1,
    marginRight: '2rem',
    textAlign: 'left', // Align text to the left
  },
  title: {
    fontSize: '3rem',
    fontWeight: 'bold', // Increase font weight
    marginBottom: '5px',
    color: '#4a6d23', // Lighter dark green color
  },
  subtitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold', // Heavier font weight for subtitle
    marginBottom: '1rem',
    marginTop: '2px',
    color: '#660000',
    paddingLeft:'10px'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    gap: '1rem', // Space between the message parts
  },
  messagePart: {
    flex: 1,
  },
  message: {
    fontSize: '1rem',
    fontWeight: '700', // Heavier font weight
    margin: 20,
    paddingLeft: '20px',
    lineHeight: '1', // Decrease line height
    color: '#1D2E28'
  },

  apol:{
    fontSize: '1rem',
    fontWeight: '600', // Heavier font weight
    margin: 10,
    // paddingLeft: '20px',
    lineHeight: '1', // Decrease line height
     color: '#1D2E28'
  },

  link: {
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '120%', // Adjust the size as needed
    height: 'auto',
    marginBottom: 6
  },
};

export default Error502;
