import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import { addApprover, getAllReviewers, getAllReviewsApi } from "api/workFlow";
import Header from "components/Header";
import { useTheme } from "@emotion/react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import i18n from "i118";
import Footer from "homepage/Footer/Footer";

const MyForms = () => {
  const { t } = useTranslation();
  const [formStructData, setFormStructData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);
  const [approverName, setApproverName] = useState("");
  const [error, setError] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [formData, setFormData] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedComment, setSelectedComment] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [reviewers, setReviewers] = useState([]);
  const [reviews, setReviews] = useState([]);
  const theme = useTheme()

  useEffect(() => {
    setSelectedComment("");
    setApproverName("");
  }, [finalizeModalOpen]);

  const handleEditRow = async (form) => {
    try {
      navigate(`/formsofx/${form.form_id}`, { state: form });
      // const response = await getSingleFormApi(form.form_id,token)
    } catch (error) {
      console.error("Error fetching form info:", error);
      // Handle error gracefully, maybe display a message to the user
    }
  };


  const handleApprove = async () => {
    try {
      const approveResponse = await addApprover(
        {
          formId: selectedFormId,
          approver: approverName,
          comment: selectedComment,
        },
        token
      );
      setFormStructData(
        formStructData.map((form) => {
          if (form.form_id === selectedFormId)
            return { ...form, finalized: true };
          else return { ...form };
        })
      );
    } catch (e) {
      console.error(e);
    }

    setFinalizeModalOpen(false);
  };
  const handleSaveChanges = () => {
    // Implement finalize logic here
  };

  const handleApproverNameChange = (e) => {
    setApproverName(e.target.value);
  };

  const handleDeleteRow = async () => {
    let response; // Declare response variable outside the try block
    try {
      response = await fetch(
        "https://api.syban-datacloud.com/forms/form-uploader/",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({ form_id: selectedFormId }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to delete form: ${response.status} ${response.statusText}`
        );
      }

      // Update form data after successful deletion
      setFormStructData(
        formStructData.filter((form) => form.form_id !== selectedFormId)
      );
    } catch (error) {
      console.error("Error deleting form:", error);
      // Log the response from the server if available
      if (response) {
        console.log("Server response:", await response.text());
      }
      // Handle error gracefully, maybe display a message to the user
      setError(error.message);
    }
    setDeleteModalOpen(false);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setFormData(null); // Clear form data when modal is closed
  };

  // Modal content for delete confirmation
  const deleteModalContent = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        width: 400,
      }}
    >
      <Typography id="delete-form-modal-description" variant="h6" gutterBottom>
        {t("sureDelete")}
      </Typography>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setDeleteModalOpen(false)}
        >
          {t("no")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteRow}
          sx={{ ml: 2 }}
        >
          {t("yes")}
        </Button>
      </Box>
    </Box>
  );

  useEffect(() => {
    console.log(reviewers);
  });
  // Modal content for finalize form
  const finalizeModalContent = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        width: 400,
      }}
    >
      <InputLabel> {t("EnterApproverName")}</InputLabel>

      <Select
        sx={{ width: "100%" }}
        label="Approver Name"
        value={approverName}
        onChange={handleApproverNameChange}
      >
        {reviewers.map((option) => (
          <MenuItem key={option.id} value={option.email}>
            {option.email}
          </MenuItem>
        ))}
      </Select>

      <Typography id="approve-form-modal-description" variant="h6" gutterBottom>
        {t("EnterComment")}
      </Typography>
      <TextField
        label="Comment"
        variant="outlined"
        value={selectedComment}
        onChange={(e) => setSelectedComment(e.target.value)}
        fullWidth
        autoFocus
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setFinalizeModalOpen(false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApprove}
          sx={{ ml: 2 }}
        >
          {t("send")}
        </Button>
      </Box>
    </Box>
  );

  useEffect(() => {
    const fetchFormStructures = async () => {
      try {
        const response = await fetch(
          "https://api.syban-datacloud.com/forms/form-uploader/",
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch form structures");
        }
        const data = await response.json();
        if (!data) {
          throw new Error("Empty response from server");
        }
        // Add unique identifiers to each row
        const formattedData = data.map((row, index) => {
          const lastModifiedDate = row.last_modified_date?.$date || ""; // Use optional chaining and provide default value
          const formattedDate = lastModifiedDate.split('T')[0] || ""; // Safe split, assuming lastModifiedDate is a valid string

          return {
            ...row,
            id: index,
            last_modified_date: formattedDate
          };
        });
        setFormStructData(formattedData);
      } catch (error) {
        console.error("Error fetching form structures:", error);
        setError(error.message);
      }
    };

    fetchFormStructures();
  }, [token]); // Make sure to include token in the dependency array

  useEffect(() => {
    const getReviewers = async () => {
      const response = await getAllReviewers(token); // Pass token as an argument
      setReviewers(response);
    };
    const getAllReviews = async () => {
      const response = await getAllReviewsApi(token); // Pass token as an argument
      setReviews(
        response.reviews.map((item, idx) => {
          return { ...item, id: idx };
        })
      );
    };
    getReviewers();
    getAllReviews();
  }, [token]);

  const [reviewsModal, setReviewsModal] = useState(false);

  const handleViewForm = async (formId) => {
    try {
      const response = await fetch("https://api.syban-datacloud.com/forms/form-info/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ form_id: formId }),

      });

      if (!response.ok) {
        throw new Error(`Failed to fetch form data: ${response.status} ${response.statusText}`);
      }

      const formData = await response.json();
      console.log(formData);

      navigate("/view-forms", { state: { formData } });
    } catch (error) {
      console.error("Error fetching form data:", error);
    }
  };

  const reviewsColumns = [
    { field: "reviewer", headerName: t("Reviewer"), flex: 1 },
    { field: "reviewer_comment", headerName: t("Reviewer Comment"), flex: 1 },
  ];
  const columns = [
    { field: "form_id", headerName: t("Form_ID"), flex: 1 },
    { field: "title", headerName: t("Title"), flex: 1 },
    { field: "last_modified_date", headerName: t("Date"), flex: 1 },
    {
      field: "finalized",
      headerName: t("Finalized"),
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value ? ( // Check if finalized is true
            <AiOutlineCheckCircle style={{ color: 'green' }} />
          ) : (
            <AiOutlineCloseCircle style={{ color: 'white' }} />
          )}
        </>
      ),
    },
    {
      field: "reviewsForm",
      headerName: t("Reviews"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setSelectedFormId(params.row.form_id);
            setReviewsModal(true);
          }}
        >
          <FaEye size={20} />
        </Button>
      ),
    },
    {
      field: "view",
      headerName: t("View_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleViewForm(params.row.form_id)}
        >
          {t("view")}
        </Button>
      ),
    },
    {
      field: "editForm",
      headerName: t("Edit_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          disabled={params.row.finalized}
          onClick={() => handleEditRow(params.row)}
        >
          {t("edit")}
        </Button>
      ),
    },
    {
      field: "deleteForm",
      headerName: t("Delete_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setDeleteModalOpen(true);
            setSelectedFormId(params.row.form_id);
          }}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {t("delete")}
          <IconButton size="small" sx={{ ml: 1, padding: 0 }}>
            <AiOutlineCloseCircle style={{ color: 'red' }} />
          </IconButton>
        </Button>
      ),
    },

    {
      field: "finalize",
      headerName: t("Finalize_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSelectedFormId(params.row.form_id);
            setFinalizeModalOpen(true);
          }}
        >
          {t("finalize")}

        </Button>
      ),
    },
  ];

  const selectedReviews = (form_id) => {
    return reviews.filter((item) => item.form_id === form_id);
  };

  const tableRTLClass = i18n.language === "en" ? "table-rtl" : "rtl";


  //===================================================================================================================
  //===================================================================================================================


  return (

    <Box sx={{
      p: {
        xs: '0.5rem 2rem', // Smaller padding for smaller screens
        sm: '1rem 1.5rem',
        md: '1.5rem 2.5rem', // Larger padding for larger screens
      },

      pr: {
        xm: '1rem',
        sm: '1rem',
      },

      m: {
        xs: '0.5rem 0.8rem', // smaller margins on small screens
        sm: '1rem 1.5rem',
        md: '1.5rem 0rem', // original margin on larger screens
      }, width: '100%', display: 'flex', flexDirection: 'column', overflowX: 'hidden', alignContent:'center'
    }}>

      {/* <Box m="1.5rem 2.5rem" sx={{width:'100%',display:'flex', flexDirection:'column'}}> */}
      <Header
        title={t("myForms")}
      />
      <Box mt="40px" height="75vh" className={tableRTLClass}
        sx={{
          width: '100%',
          overflowX: 'auto', // Allows horizontal scrolling if content overflows

          mr: { xs: "20px", sm: "0px" },

          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#6B8A7A",
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#6B8A7A",
            color: "#fff"

          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >

        <DataGrid
          loading={!formStructData.length}
          rows={formStructData}
          columns={columns}
          components={{ ColumnMenu: CustomColumnMenu }}
        />
      </Box>

      {/* Delete confirmation modal */}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-form-modal"
        aria-describedby="delete-form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: {
              xs: 2, // Smaller padding on smaller screens
              md: 4, // Default padding on larger screens
            },
            width: {
              xs: '90%', // Adjust width on smaller screens
              sm: 400,   // Fixed width on larger screens
            },
          }}
        >
          <Typography
            id="delete-form-modal-description"
            variant="h6"
            gutterBottom
          >
            {t("confirmDelete")}
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setDeleteModalOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteRow}
              sx={{ ml: 2 }}
            >
              {t("delete")}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Reviews modal */}
      <Modal
        open={reviewsModal}
        onClose={() => setReviewsModal(false)}
        aria-labelledby="reviews-form-modal"
        aria-describedby="reviews-form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: {
              xs: 2, // Smaller padding on smaller screens
              md: 4, // Default padding on larger screens
            },
            width: {
              xs: '90%', // Adjust width on smaller screens
              sm: 400,   // Fixed width on larger screens
            },
          }}
        >
          <Typography
            id="reviews-form-modal-description"
            variant="h6"
            gutterBottom
            align="center"
          >
            {t("Reviews")}
          </Typography>
          <Box mt="40px" height={{ xs: '50vh', md: '40vh' }}>
            <DataGrid
              loading={!reviews.length}
              rows={selectedReviews(selectedFormId)}
              columns={reviewsColumns}
              components={{ ColumnMenu: CustomColumnMenu }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setReviewsModal(false)}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Finalize modal */}
      <Modal
        open={finalizeModalOpen}
        onClose={() => setFinalizeModalOpen(false)}
        aria-labelledby="finalize-form-modal"
        aria-describedby="finalize-form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: {
              xs: 2, // Smaller padding on smaller screens
              md: 4, // Default padding on larger screens
            },
            width: {
              xs: '90%', // Adjust width on smaller screens
              sm: 400,   // Fixed width on larger screens
            },
          }}
        >
          {finalizeModalContent}
        </Box>
      </Modal>

      {/* Edit form modal */}
      <Modal
        open={editModalOpen}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-form-modal"
        aria-describedby="edit-form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: {
              xs: 2, // Smaller padding on smaller screens
              md: 4, // Default padding on larger screens
            },
            width: {
              xs: '90%', // Adjust width on smaller screens
              sm: 400,   // Fixed width on larger screens
            },
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t("EditForm")}
          </Typography>
          {formData && (
            <form>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCloseEditModal}
                >
                  {t("cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveChanges}
                  sx={{ ml: 2 }}
                >
                  {t("save")}
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </Modal>

      <Footer />
    </Box>

    // </div>
  );
};

export default MyForms;