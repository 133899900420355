import axios from 'axios';

const API_URL = 'https://api.syban-datacloud.com';

export const token = localStorage.getItem("token");

// Function to set Authorization header dynamically
export const setAuthToken = (token) => {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`; 
};

export const getAllUsers = () => axios.get(`${API_URL}/accounts/admin/`);
export const registerUser = (userData) => axios.post(`${API_URL}/auth/users/`, userData);
export const getUser = (id) => axios.get(`${API_URL}/accounts/admin/${id}`);
export const updateUser = (id, userData) => axios.put(`${API_URL}/accounts/admin/${id}/`, userData);
export const patchUser = (id, userData) => axios.patch(`${API_URL}/accounts/admin/${id}/`, userData);
export const deleteUser = (id) => axios.delete(`${API_URL}/accounts/admin/${id}/`);
export const getAllForms = () => axios.get('/forms/all_forms/');