import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, IconButton, Button, TableFooter,
  TablePagination, Box, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Snackbar, Typography, ThemeProvider, CssBaseline
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Header from 'components/Header';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import Footer from 'homepage/Footer/Footer';
import theme from './theme';
import DeleteFormModal from 'components/DeleteFormModal';

const initialUserState = {
  id: '',
  password: '',
  email: '',
  first_name: '',
  last_name: '',
  re_password: '',
  role: ''
};

const UserManagement = () => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [newUser, setNewUser] = useState(initialUserState);
  const [selectedUser, setSelectedUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://api.syban-datacloud.com/accounts/admin/', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data)
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
    setNewUser(initialUserState);
  };

  const handleClose = () => {
    setOpen(false);
    setNewUser(initialUserState);
  };

  const handleUpdateOpen = (user) => {
    setSelectedUser(user);
    setNewUser({
      id: user.id,
      password: '',
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      re_password: '',
      role: user.role
    });
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => {
    setSelectedUser(null);
    setUpdateOpen(false);
  };

  const handleDeleteOpen = (userId) => {
    setDeleteUserId(userId);
    setConfirmOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteUserId(null);
    setConfirmOpen(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await fetch(`https://api.syban-datacloud.com/accounts/admin/${deleteUserId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchUsers();
      setSnackbarMessage(t('User deleted successfully'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbarMessage(t('Error deleting user'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      handleDeleteClose();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://api.syban-datacloud.com/auth/users/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      });

      if (response.status === 400) {
        const errorData = await response.json();
        for (let key in errorData) {
          if (typeof errorData[key] === "object") {
            for (let subKey in errorData[key]) {
              showSnackBarMessage(`${key}: ${errorData[key][subKey]}`, 'error');
            }
            return;
          }
          showSnackBarMessage(`${key}: ${errorData[key]}`, 'error');
        }
        return;
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchUsers();
      handleClose();
      showSnackBarMessage(t('User registered successfully'), 'success');
    } catch (error) {
      console.error('Error registering user:', error);
      showSnackBarMessage(t('Error registering user'), 'error');
    }
  };

  const showSnackBarMessage = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
    setSnackbarOpen(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      const response = await fetch(`https://api.syban-datacloud.com/accounts/admin/${selectedUser.id}/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchUsers();
      handleUpdateClose();
      setSnackbarMessage(t('User updated successfully'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbarMessage(t('Error updating user'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDetailsOpen = async (userId) => {
    try {
      const response = await fetch(`https://api.syban-datacloud.com/accounts/admin/${userId}/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUserDetails(data);
      setDetailsOpen(true);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleDetailsClose = () => {
    setUserDetails(null);
    setDetailsOpen(false);
  };

  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';
 
    return (
      <>
       <ThemeProvider theme={theme} sx={{ direction: direction , width: '100%'}}>
      <CssBaseline />
      {/* <Box sx={{ p: 3, backgroundColor: theme.palette.background.default, minHeight: '100vh', direction: direction }}> */}
      <Box
  sx={{
    p: 3,
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    direction: direction,
    // display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    width:'100%',
    overflowX: 'hidden' 
  }}
>
            <Box sx={{ mt: '1rem', mb: '2rem', mx: 'auto', width: '100%' }}>
              <Header title={t('Admin Page')} />
            </Box>
    
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ mb: 2, direction: direction }}>
              {t('Add New User')}
            </Button>
            <TableContainer component={Paper} sx={{ direction: direction }}>
              <Table>
                <TableHead sx={{ backgroundColor: '#6B8A7A' }}>
                  <TableRow>
                    <TableCell sx={{ color: '#FFFFFF' }}>{t('id')}</TableCell>
                    <TableCell sx={{ color: '#FFFFFF' }}>{t('Email')}</TableCell>
                    <TableCell sx={{ color: '#FFFFFF' }}>{t('First Name')}</TableCell>
                    <TableCell sx={{ color: '#FFFFFF' }}>{t('Last Name')}</TableCell>
                    <TableCell sx={{ color: '#FFFFFF' }}>{t('Role')}</TableCell>
                    <TableCell sx={{ color: '#FFFFFF' }}>{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.first_name}</TableCell>
                      <TableCell>{user.last_name}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleUpdateOpen(user)}>
                          <UpdateIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteOpen(user.id)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => handleDetailsOpen(user.id)}>
                          <AccountCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter sx={{ direction: direction }}>
                  <TableRow sx={{ direction: direction }}>
                    <TablePagination
                      sx={{ direction: direction }}
                      rowsPerPageOptions={[5, 10, 25]}
                      count={users.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <DeleteFormModal />
            <Footer />
    
            {/* Add New User Dialog */}
            <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          sx={{
            direction: direction,
            '& .MuiDialog-paper': {
              maxWidth: '90vw',
              width: '100%',
              maxHeight: '90vh',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '@media (max-width: 768px)': {
                margin: '8px', // Add some margin to avoid touching screen edges
                padding: '16px', // Adjust padding to fit content better
                maxWidth: '55%', 
              },
            },
            direction:direction
          }}
        >
              <DialogTitle>{t('Add New User')}</DialogTitle>
              <DialogContent sx={{ direction: direction }}>
                <TextField
                  sx={{ direction: direction }}
                  autoFocus
                  margin="dense"
                  label={t('id')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="id"
                  value={newUser.id}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('Email')}
                  type="email"
                  fullWidth
                  variant="outlined"
                  name="email"
                  value={newUser.email}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  label={t('First Name')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="first_name"
                  value={newUser.first_name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  label={t('Last Name')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="last_name"
                  value={newUser.last_name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  label={t('Password')}
                  type="password"
                  fullWidth
                  variant="outlined"
                  name="password"
                  value={newUser.password}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  label={t('Confirm Password')}
                  type="password"
                  fullWidth
                  variant="outlined"
                  name="re_password"
                  value={newUser.re_password}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  label={t('Role')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="role"
                  value={newUser.role}
                  onChange={handleInputChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('Cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  {t('Add')}
                </Button>
              </DialogActions>
            </Dialog>
    
            {/* Update User Dialog */}
            <Dialog
          open={updateOpen}
          onClose={handleUpdateClose}
          fullWidth
          maxWidth="sm"
          sx={{
            direction: direction,
            '& .MuiDialog-paper': {
              maxWidth: '90vw',
              width: '100%',
              maxHeight: '90vh',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '@media (max-width: 768px)': {
                margin: '8px', // Add some margin to avoid touching screen edges
                padding: '16px', // Adjust padding to fit content better
                maxWidth: '75%', 
              },
            },
            direction:direction
          }}
        >
              <DialogTitle>{t('Update User')}</DialogTitle>
              <DialogContent sx={{ direction: direction }}>
                <TextField
                  sx={{ direction: direction }}
                  autoFocus
                  margin="dense"
                  label={t('id')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="id"
                  value={newUser.id}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('Email')}
                  type="email"
                  fullWidth
                  variant="outlined"
                  name="email"
                  value={newUser.email}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('First Name')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="first_name"
                  value={newUser.first_name}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('Last Name')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="last_name"
                  value={newUser.last_name}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('Password')}
                  type="password"
                  fullWidth
                  variant="outlined"
                  name="password"
                  value={newUser.password}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('Confirm Password')}
                  type="password"
                  fullWidth
                  variant="outlined"
                  name="re_password"
                  value={newUser.re_password}
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ direction: direction }}
                  margin="dense"
                  label={t('Role')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="role"
                  value={newUser.role}
                  onChange={handleInputChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleUpdateClose} color="primary">
                  {t('Cancel')}
                </Button>
                <Button onClick={handleUpdateSubmit} color="primary">
                  {t('Update')}
                </Button>
              </DialogActions>
            </Dialog>
    
            {/* Confirmation Dialog */}
            <Dialog
          open={confirmOpen}
          onClose={handleDeleteClose}
          fullWidth
          maxWidth="sm"
          sx={{
            direction: direction,
            '& .MuiDialog-paper': {
              maxWidth: '90vw',
              width: '100%',
              maxHeight: '90vh',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              '@media (max-width: 768px)': {
                margin: '8px', // Add some margin to avoid touching screen edges
                padding: '16px', // Adjust padding to fit content better
                maxWidth: '70%', 
              },
            },
            direction:direction
          }}
        >
              <DialogTitle>{t('Confirm Delete')}</DialogTitle>
              <DialogContent sx={{ direction: direction }}>
                <Typography>{t('Are you sure you want to delete this user?')}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteClose} color="primary">
                  {t('Cancel')}
                </Button>
                <Button onClick={handleDeleteConfirm} color="primary">
                  {t('Delete')}
                </Button>
              </DialogActions>
            </Dialog>
    
            {/* User Details Dialog */}
            <Dialog
          open={detailsOpen}
          onClose={handleDetailsClose}
          fullWidth
          maxWidth="sm"
          sx={{
            direction: direction,
            '& .MuiDialog-paper': {
              // maxWidth: '90vw',
              // width: '100%',
              maxHeight: '90vh',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              
              '@media (max-width: 768px)': {
                margin: '8px', // Add some margin to avoid touching screen edges
                padding: '16px', // Adjust padding to fit content better
                maxWidth: '70%', 
                display: 'flex',
                justifyContent: 'space-between'
              },
            },
          }}
        >
              <DialogTitle>{t('User Details')}</DialogTitle>
              <DialogContent sx={{ direction: direction }}>
                {userDetails && (
                  <Box>
                    <Typography variant="body1"><strong>{t('id')}:</strong> {userDetails.id}</Typography>
                    <Typography variant="body1"><strong>{t('Email')}:</strong> {userDetails.email}</Typography>
                    <Typography variant="body1"><strong>{t('First Name')}:</strong> {userDetails.first_name}</Typography>
                    <Typography variant="body1"><strong>{t('Last Name')}:</strong> {userDetails.last_name}</Typography>
                    <Typography variant="body1"><strong>{t('Role')}:</strong> {userDetails.role}</Typography>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDetailsClose} color="primary">
                  {t('Close')}
                </Button>
              </DialogActions>
            </Dialog>
    
            {/* Snackbar for notifications */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              severity={snackbarSeverity}
            />
          </Box>
        </ThemeProvider>
      </>
    );
    
  
};

export default UserManagement;