import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Box,
  Grid,
  Card,
  CardContent,
  CssBaseline,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';
import DataTable from './DataTable';
import Footer from 'homepage/Footer/Footer';
import i18n from 'i118';
import Header from 'components/Header';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const theme = createTheme({
  palette: {
    primary: { main: '#7AAB85' },
    secondary: { main: '#009688' },
    background: { default: '#fff' },
  },
  typography: {
    h4: { fontWeight: 600, color: '#003366' },
    h5: { fontWeight: 500, color: '#003366' },
    body1: { color: '#333' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {

          padding: '10px 20px',
          fontWeight: 600,

        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {

        },
      },
    },
  },
});

const Daily = () => {
  const { t } = useTranslation();
  const [dashboardData, setDashboardData] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardError, setDashboardError] = useState(null);
  const [timeInterval, setTimeInterval] = useState('last_month');
  const [chartType, setChartType] = useState('line');
  const [selectedChart, setSelectedChart] = useState('number_of_active_contracts');
  const [newChartData, setNewChartData] = useState(null);
  const [newChartLoading, setNewChartLoading] = useState(true);
  const [newChartError, setNewChartError] = useState(null);
  const [newChartData2, setNewChartData2] = useState(null);
  const [newChartLoading2, setNewChartLoading2] = useState(true);
  const [newChartError2, setNewChartError2] = useState(null);
  const token = localStorage.getItem("token");

  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  const fetchDashboardData = async (interval) => {
    try {
      const response = await fetch('https://api.syban-datacloud.com/dashboard/dashboard/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({ dashboard_name: 'number_of_active_contracts', time_interval: interval })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setDashboardData(result);
    } catch (error) {
      setDashboardError(error.message);
    } finally {
      setDashboardLoading(false);
    }
  };

  const fetchNewChartData = async (interval) => {
    try {
      const response = await fetch('https://api.syban-datacloud.com/dashboard/dashboard/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({ dashboard_name: 'value_of_active_contracts', time_interval: interval })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setNewChartData(result);
    } catch (error) {
      setNewChartError(error.message);
    } finally {
      setNewChartLoading(false);
    }
  };

  const fetchNewChartData2 = async (interval) => {
    try {
      const response = await fetch('https://api.syban-datacloud.com/dashboard/dashboard/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({ dashboard_name: 'credit_and_actual_income', time_interval: interval })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setNewChartData2(result);
    } catch (error) {
      setNewChartError2(error.message);
    } finally {
      setNewChartLoading2(false);
    }
  };

  useEffect(() => {
    fetchDashboardData(timeInterval);
    fetchNewChartData(timeInterval);
    fetchNewChartData2(timeInterval);
  }, [token, timeInterval]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDashboardLoading(true);
    setDashboardError(null);
    setDashboardData(null);
    setNewChartLoading(true);
    setNewChartError(null);
    setNewChartData(null);
    setNewChartLoading2(true);
    setNewChartError2(null);
    setNewChartData2(null);
    await fetchDashboardData(timeInterval);
    await fetchNewChartData(timeInterval);
    await fetchNewChartData2(timeInterval);
  };

  const processDataForLineChart = () => {
    if (!dashboardData) return { labels: [], datasets: [] };

    const contracts = dashboardData.message.active_contracts;
    const dateCounts = {};

    contracts.forEach(contract => {
      const contractDate = new Date(contract['Start Date']);
      const datePart = contractDate.toISOString().split('T')[0];

      if (!dateCounts[datePart]) {
        dateCounts[datePart] = 0;
      }
      dateCounts[datePart]++;
    });

    const labels = Object.keys(dateCounts).sort();
    const data = labels.map(label => dateCounts[label]);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Number of Active Contracts',
          data: data,
          borderColor: '#FF7400',

          fill: true,
        },
      ],
    };
  };

  const processDataForBarChart = () => {
    if (!newChartData) return { labels: [], datasets: [] };

    const contracts = newChartData.message;
    const labels = contracts.map(contract => contract['Contract Title']);
    const data = contracts.map(contract => parseFloat(contract['Contract Price']));

    return {
      labels: labels,
      datasets: [
        {
          label: 'Contract Prices',
          data: data,
          backgroundColor: 'rgba(255,99,132,0.6)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
        },
      ],
    };
  };

  const processDataForNewChart = () => {
    if (!newChartData2) return { labels: [], datasets: [] };

    const installments = newChartData2.message.installment;
    const payments = newChartData2.message.payment;

    const labels = installments.map(item => item['Due Date']);
    const installmentAmounts = installments.map(item => parseFloat(item.Amount));
    const paymentAmounts = payments.map(item => parseFloat(item.Amount));

    return {
      labels: labels,
      datasets: [
        {
          label: 'Installments',
          data: installmentAmounts,
          borderColor: '#EF02FF',
          backgroundColor: 'rgba(62, 149, 205, 0.2)',
          fill: true,
        },
        {
          label: 'Payments',
          data: paymentAmounts,
          borderColor: '#f1c40f',
          backgroundColor: 'rgba(241, 196, 15, 0.2)',
          fill: true,
        },
      ],
    };
  };

  const chartDataForLineChart = processDataForLineChart();
  const chartDataForBarChart = processDataForBarChart();
  const chartDataForNewChart = processDataForNewChart();

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat(i18n.language).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const newChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat(i18n.language).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  const renderChartAndTable = () => {
    switch (selectedChart) {
      case 'number_of_active_contracts':
        return (
          <>
            <Typography variant="h5" component="div">
              {t('numberOfActiveContracts')}
            </Typography>
            <Line data={chartDataForLineChart} options={lineChartOptions} />
            <DataTable
              data={dashboardData.message.active_contracts}
              columns={['Start Date', 'Contract Title']}
            />
          </>
        );
      case 'value_of_active_contracts':
        return (
          <>
            <Typography variant="h5" component="div">
              {t('valueOfActiveContracts')}
            </Typography>
            {chartType === 'line' ? (
              <Line data={chartDataForBarChart} options={barChartOptions} />
            ) : (
              <Bar data={chartDataForBarChart} options={barChartOptions} />
            )}
            <DataTable
              data={newChartData.message}
              columns={['Contract Title', 'Contract Price']}
            />
          </>
        );
      case 'credit_and_actual_income':
        return (
          <>
            <Typography variant="h5" component="div">
              {t('Credit and actual income')}
            </Typography>
            <Line data={chartDataForNewChart} options={newChartOptions} />
            <DataTable
              data={newChartData2.message.installment}
              columns={['Due Date', 'Amount']}
            />
            <DataTable
              data={newChartData2.message.payment}
              columns={['Payment Date', 'Amount']}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ direction: direction, overflowX: 'hidden' }}>
      <Box sx={{ px: 2, width: '100%' }}>
        <Header title={t("wasteManagementDashboard")} />
      </Box>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters>
          <Box sx={{ my: 4, width: '100%' }}>
            <Paper elevation={3} sx={{ p: 4, borderRadius: '16px', width: '100%' }}>
              <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    select
                    label={t('timeInterval')}
                    value={timeInterval}
                    onChange={(e) => setTimeInterval(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="last_day">{t('lastDay')}</MenuItem>
                    <MenuItem value="last_week">{t('lastWeek')}</MenuItem>
                    <MenuItem value="last_month">{t('lastMonth')}</MenuItem>
                  </TextField>
                  <TextField
                    select
                    label={t('chartType')}
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="line">{t('lineChart')}</MenuItem>
                    <MenuItem value="bar">{t('barChart')}</MenuItem>
                  </TextField>
                  <TextField
                    select
                    label={t('selectChart')}
                    value={selectedChart}
                    onChange={(e) => setSelectedChart(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="number_of_active_contracts">{t('Number Of Active Contracts')}</MenuItem>
                    <MenuItem value="value_of_active_contracts">{t('Value Of Active Contracts')}</MenuItem>
                    <MenuItem value="credit_and_actual_income">{t('Credit and actual income')}</MenuItem>
                  </TextField>
                </Box>
                {/*}
                <Button type="submit" variant="contained" color="primary">
                  {t('refresh')}
                </Button>*/}
              </Box>

              {dashboardLoading || newChartLoading || newChartLoading2 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {dashboardError || newChartError || newChartError2 ? (
                    <Typography color="error">
                      {dashboardError || newChartError || newChartError2}
                    </Typography>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}> {/* Left side: Chart */}
                                {selectedChart === 'number_of_active_contracts' && (
                                  <Line data={chartDataForLineChart} options={lineChartOptions} />
                                )}
                                {selectedChart === 'value_of_active_contracts' && chartType === 'line' && (
                                  <Line data={chartDataForBarChart} options={barChartOptions} />
                                )}
                                {selectedChart === 'value_of_active_contracts' && chartType === 'bar' && (
                                  <Bar data={chartDataForBarChart} options={barChartOptions} />
                                )}
                                {selectedChart === 'credit_and_actual_income' && (
                                  <Line data={chartDataForNewChart} options={newChartOptions} />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}> {/* Right side: Data Table */}
                                {selectedChart === 'number_of_active_contracts' && (
                                  <DataTable
                                    data={dashboardData.message.active_contracts}
                                    columns={['Start Date', 'Contract Title']}
                                  />
                                )}
                                {selectedChart === 'value_of_active_contracts' && (
                                  <DataTable
                                    data={newChartData.message}
                                    columns={['Contract Title', 'Contract Price']}
                                  />
                                )}
                                {selectedChart === 'credit_and_actual_income' && newChartData2?.message && (
                                  <>
                                    {newChartData2.message.installment && (
                                      <DataTable
                                        data={newChartData2.message.installment}
                                        columns={['Due Date', 'Amount']}
                                      />
                                    )}

                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Paper>
          </Box>
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default Daily;