import axios from "axios";

const API_BASE_URL = "https://api.syban-datacloud.com/";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Helper function to get token from local storage
const getToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refreshToken');
const setToken = (token) => localStorage.setItem('token', token);

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const response = await axios.post(`${API_BASE_URL}auth/refresh`, { token: refreshToken });
        if (response.data.token) {
          setToken(response.data.token);
          error.config.headers['Authorization'] = `Bearer ${response.data.token}`;
          return axios.request(error.config);
        }
      }
    }
    return Promise.reject(error);
  }
);

// Helper function to attach Authorization header
const authHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};
export const addApprover = async ({ formId, approver, comment }, token) => {
  try {
    const response = await api.post(
      "flow/approver/",
      {
        form_id: formId,
        approver,
        comment,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAdminInbox = async (token) => {
  try {
    const response = await api.get("flow/inbox/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAllReviewers = async (token) => {
  try {
    const response = await api.get("accounts/reviewers/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getAllReviewsApi = async (token) => {
  try {
    const response = await api.get("flow/reviews/", {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const rejectReview = async ({ review_id, comment }, token) => {
  try {
    const response = await api.post(
      "flow/reject/",
      { review_id, comment },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const approveReview = async ({ review_id, comment }, token) => {
  try {
    const response = await api.post(
      "flow/approve/",
      { review_id, comment },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// New function to get form for review
export const getFormForReview = async (reviewId, token) => {
  try {
    const response = await api.get(`flow/inbox/${reviewId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

