import React from 'react';
import styled from 'styled-components';

const NewsItemContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
  }
`;

const NewsHeadline = styled.h3`
  font-size: 24px;
  color: #715F4C;
  margin-bottom: 10px;
  text-align: center;
 
 
  display: flex;
  justify-content:center;
  padding-bottom: 15px;
 
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Darker and more opaque */
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2); /* Darker and larger blur */
  @media (max-width: 768px) {
    font-size: 20px;
  }


`;

const NewsContent = styled.p`
  font-size: 16px;
  color: #333;
  line-height: 1.6;
`;

const NewsItem = ({ headline, content }) => {
  return (
    <NewsItemContainer>
      <NewsHeadline>{headline}</NewsHeadline>
      <NewsContent>{content}</NewsContent>
    </NewsItemContainer>
  );
};

export default NewsItem;
