import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardActions,
  Tooltip,
  Stack,
  Avatar,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const CustomCard = styled(Card)(({ theme }) => ({
  background: `linear-gradient(145deg, ${theme.palette.background.paper} 30%, ${theme.palette.grey[100]} 100%)`,
  boxShadow: theme.shadows[6],
  borderRadius: '20px',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[12],
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  padding: '10px 24px',
  fontWeight: '600',
  textTransform: 'none',
  boxShadow: theme.shadows[4],
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[8],
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
  },
}));

const DeleteFormModal = () => {
  const [formGroups, setFormGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [forms, setForms] = useState([]);
  const [openFormsModal, setOpenFormsModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();
  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  useEffect(() => {
    const fetchFormGroups = async () => {
      try {
        const response = await fetch('https://api.syban-datacloud.com/forms/list-form-groups/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFormGroups(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFormGroups();
  }, []);

  const handleDelete = async () => {
    if (!selectedGroup) return;

    try {
      const response = await fetch('https://api.syban-datacloud.com/forms/define_form/new_group', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({ ref: selectedGroup.ref }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete the form group');
      }

      setFormGroups((prevGroups) => prevGroups.filter((group) => group.ref !== selectedGroup.ref));
      setOpenDialog(false);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleOpenDialog = (group) => {
    setSelectedGroup(group);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGroup(null);
  };

  const handleViewForms = async (group) => {
    try {
      const response = await fetch(`https://api.syban-datacloud.com/forms/list-forms/${group.ref}/en`);
      if (!response.ok) {
        throw new Error('Failed to fetch forms');
      }
      const data = await response.json();
      setForms(data);
      setOpenFormsModal(true);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const handleCloseFormsModal = () => {
    setOpenFormsModal(false);
  };

  const handleDeleteForm = async () => {
    if (!selectedForm) return;

    try {
      const response = await fetch('https://api.syban-datacloud.com/forms/form_struct/', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          form_struct_id: selectedForm.id,
          language: 'en'
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete the form');
      }

      setForms((prevForms) => prevForms.filter((form) => form.id !== selectedForm.id));
      setSelectedForm(null);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <div className={direction}>
      <Box padding={4} bgcolor="background.default">
        <Typography variant="h6" gutterBottom>
          {t('formGroupsManagement')}
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {formGroups.map((group) => (
            <Grid item xs={12} sm={6} md={4} key={group.ref}>
              <CustomCard>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar sx={{ bgcolor: 'primary.main', marginRight: 2 }}>
                      {t(`${group.name.en?.charAt(0)}`) || t('defaultGroupInitial')}
                    </Avatar>
                    <Typography variant="h6" fontWeight="bold">
                      {t(`${group.name.en}`) || t('unnamedGroup')}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
                    <Tooltip title={t('viewForms')}>
                      <ActionButton
                        variant="contained"
                        color="primary"
                        startIcon={<VisibilityOutlinedIcon />}
                        onClick={() => handleViewForms(group)}
                      >
                        {t('viewForms')}
                      </ActionButton>
                    </Tooltip>
                    <Tooltip title={t('deleteGroup')}>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDialog(group)}
                        sx={{
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': { transform: 'scale(1.2)' },
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </CardActions>
              </CustomCard>
            </Grid>
          ))}
        </Grid>

        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth  sx={{
    '& .MuiDialog-paper': {
      '@media (max-width: 768px)': {
        margin: '8px', // Add some margin to avoid touching screen edges
        padding: '16px', // Adjust padding to fit content better
        maxWidth: '75%', 
      },
    },
    direction:direction
  }}>
          <DialogTitle>{t('deleteFormGroup')}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {t('confirmDeleteGroup')} "<strong>{selectedGroup?.name.en}</strong>"?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant="outlined" color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              {t('delete')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
  open={openFormsModal}
  onClose={handleCloseFormsModal}
  maxWidth="md" // Larger dialog
  fullWidth
  sx={{
    '& .MuiDialog-paper': {
      '@media (max-width: 768px)': {
        margin: '8px', // Add some margin to avoid touching screen edges
        padding: '16px', // Adjust padding to fit content better
        maxWidth: '75%', 
      },
    },
    direction:direction
  }}
>
  <DialogTitle align="center">{t('formsInGroup')}</DialogTitle>
  <DialogContent>
    <Grid
      container
      spacing={3}
      sx={{
        '@media (max-width: 600px)': {
          spacing: 1, // Decrease spacing for small screens
        },
      }}
    >
      {forms.map((form) => (
        <Grid item xs={12} sm={6} key={form.id}>
          <CustomCard>
            <CardContent>
              <Typography variant="body1" fontWeight="normal">
                {t(`${form.title}`)}
              </Typography>
            </CardContent>
            <CardActions>
              <Tooltip title={t('deleteForm')}>
                <IconButton
                  color="error"
                  onClick={() => setSelectedForm(form)}
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': { transform: 'scale(1.2)' },
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
          </CustomCard>
        </Grid>
      ))}
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseFormsModal} variant="outlined" color="primary">
      {t('close')}
    </Button>
    <Button onClick={handleDeleteForm} variant="contained" color="error" disabled={!selectedForm}>
      {t('deleteSelectedForm')}
    </Button>
  </DialogActions>
</Dialog>
      </Box>
    </div>
  );
};

export default DeleteFormModal;