import React, { useState } from 'react';
import './Header.css';
import Logo from '../assets/logo.png';
import Bars from '../assets/bars.png';
import { Link } from 'react-scroll';

import { useTranslation } from 'react-i18next';
import i18n from 'i118';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  LanguageOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import {
  Button,
} from "@mui/material";
import i18next from 'i18next';

const Header = () => {
  const tableRTLClass = i18next.language === "en" ? "table-rtl" : "ltr";
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);

  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <div className={tableRTLClass}>
      <div className='header'>
        <div className='logo'>
          <img src={Logo} alt={t('Logo')} />
        </div>

        {(menuOpened === false && mobile === true) ? (
          <div
            style={{ backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px' }}
            onClick={() => setMenuOpened(true)}
          >
            <img src={Bars} alt={t('Menu Bars')} style={{ width: '1.5rem', height: '1.5rem' }} />
          </div>
        ) : (
          <ul className='header-menu'>
            <li onClick={() => setMenuOpened(false)}>
              <Link
                span={true}
                smooth={true}
                onClick={() => window.location.href = '/home'}
              >{t('Home')}</Link>
            </li>
            <li onClick={() => setMenuOpened(false)}>
              <Link
                to='taskmanagement'
                span={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >{t('Features')}</Link>
            </li>
            <li onClick={() => setMenuOpened(false)}>
              <Link
                to='workflow'
                span={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >{t('Creating value together')}</Link>
            </li>
            <li onClick={() => setMenuOpened(false)}>
              <Link
                span={true}
                onClick={() => window.location.href = '/blog'}
              >{t('Blog')}</Link>
            </li>
            <li onClick={() => setMenuOpened(false)}>
              <Link
                to='ContactUs'
                span={true}
                smooth={true}
                onClick={() => setMenuOpened(false)}
              >{t('About Us')}</Link>
            </li>
            <li>
            <Button
            onClick={handleClick}
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              color: "#6B8A7A",

              backgroundColor: "#ffffff",
              fontWeight: 600,
              cursor: "pointer"
            }}
          >
            <LanguageOutlined sx={{ fontSize: "25px" }} />
            <ArrowDropDownOutlined />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MenuItem onClick={() => changeLanguage("en")}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage("fa")}>فارسی</MenuItem>
          </Menu>
          </li>
          </ul>
        )}

        <div className='language-switcher'>
            
        </div>

        <div className='login-button'>
          <button onClick={() => window.location.href = '/login'}>{t('Login')}</button>

          {/* Language Switcher with Dropdown */}
         

          <button className='register-button' onClick={() => window.location.href = '/contact-us'}>{t('Contact Us')}</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
