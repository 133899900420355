import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Wastemanagement from "scenes/watemanagement";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Home from "./homepage/home";
import HealthAndSafety from "scenes/healt&saftey";
import CRMAndFinance from "scenes/CRM&Finance";
import Formsofx from "formComponents";
import FormGenerator from "scenes/formGenerator";
import MyForms from "scenes/MyForms";
import WasteManagementDashboard from "scenes/wastemanagementDashboard";
import FormGroups from "scenes/formsgroups";
import UnauthorizedPage from "./401";
import Error500 from "500";
import Error502 from "502";
import Error503 from "503";
import Error504 from "504";
import Error404 from "404";
import Error403 from "403";


import ViewForms from "scenes/viewForms";
import LoginForm from "authSection/LoginForm";
import ForgotPasswordForm from "authSection/ForgotPasswordForm";
import ContactUs from "authSection/ContactUs";
import DataDashboard from "scenes/dataDashboard/DataDashboard";
import UserManagement from "scenes/userManagement";
import Company from "scenes/company";
import Blog from "homepage/Blog/Blog";
import SafetyDashboard from "scenes/safetyDashboard";
import UserProfile from "scenes/UserProfile";
import Formsofx2 from "fillNewForm";
import { token } from "scenes/userManagement/services/userService";

function App() {
  const mode = useSelector((state) => state.global.mode);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const handleLogin = (userData) => {
    // Handle successful login (e.g., store user data in state/local storage, redirect, etc.)
    // window.location.href = '/dashboard'; // Redirect to dashboard after successful login
  };

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm/>} />
            <Route path="/401" element={<UnauthorizedPage/>} />
            <Route path="/500" element={<Error500 />} />
            <Route path="/502" element={<Error502 />} />
            <Route path="/503" element={<Error503 />} />
            <Route path="/504" element={<Error504 />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/403" element={<Error403 />} />

            <Route element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/wastemanagement" element={<Wastemanagement />} />
              <Route path="/health_and_safety" element={<HealthAndSafety />} />
              <Route path="/formsofx/:formStructId" element={<Formsofx />} />
              <Route path="/formsofx2/:formStructId" element={<Formsofx2 />} />
              <Route path="/formgenerator" element={<FormGenerator />} />
              <Route path="/crm_and_finance" element={<CRMAndFinance />} />
              <Route path="/formsgroups" element={<FormGroups />} />
              <Route path="/myforms/" element={<MyForms />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/inbox" element={<Admin />} />
              <Route path="/view-forms" element={<ViewForms token={token} />} />
              <Route path="/safety-dashboards" element={<SafetyDashboard />} />
              <Route path="/wastemanagementdashboard" element={<WasteManagementDashboard />} />
              <Route path="/data-dashboard" element={<DataDashboard />} />
              <Route path="/user-management" element={<UserManagement/>} />
              <Route path="/company" element={<Company/>} />
              <Route path="/user-profile" element={<UserProfile/>} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
