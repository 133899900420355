import React, { useState } from 'react';
import './Hero.css';
import Header from '../Header/Header';
import hero from '../assets/hero.png';
import earth from '../assets/earth.png';
import { useTranslation } from "react-i18next";

// Import icons from a library like FontAwesome or use your own assets
import { FaCog, FaHandshake, FaBullseye, FaChevronDown, FaChartLine, FaChartBar, FaFileAlt } from 'react-icons/fa';

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(null);

  // Check if the current language is Farsi (or any RTL language)
  const isRtl = i18n.language === 'fa'; // 'fa' is the language code for Farsi

  const plans = [
    { name: t('Modular Microservices for Comprehensive Data Solutions'), details: t('At Syban, we have developed a robust set of modular microservices tailored to meet diverse business needs. Our offerings include advanced solutions for data collection, data visualization, data analytics, predictive analytics, and anomaly detection. These microservices serve as the building blocks for creating new, innovative solutions that drive business success. By leveraging our modular approach, we can deliver highly flexible and scalable co-branded SaaS solutions, ensuring our partners can provide their clients with cutting-edge technology that adapts to their specific requirements.'), icon: <FaCog /> },
    { name: t('Join Us in Making a Difference'), details: t('We invite consulting companies and research institutes from various fields—management, accounting, finance, healthcare, and beyond—to partner with us. If you are developing solutions for your clients and want to transform your innovative ideas into reality, join Syban. Together, we can create impactful, data-driven solutions that help your business clients achieve their goals and thrive in a competitive landscape.'), icon: <FaHandshake /> },
    { name: t('Our Core Principles'), details: t('At Syban, our core principles drive the development and delivery of our solutions.'), icon: <FaBullseye /> },
  ];

  const cardInfo = [
    { title: t('Data Visualization'), icon: <FaChartBar /> },
    { title: t('KPI-Driven Data Insights'), icon: <FaChartLine /> },
    { title: t('Data Collection Forms'), icon: <FaFileAlt /> },
  ];

  return (
    <div className={`hero-container ${isRtl ? 'rtl' : ''}`}>
      <Header />
      <div className="hero-content">
        <div className='hero-left'>
          <div className="hero-title">
            <h1>{t('Syban | Data Management Suite')}</h1>
            <p className="hero-subtitle">{t('Empowering Businesses to Maximize Value from Data')}</p>
            <p className="hero-description">
              {t('A suite of cloud-based tools offering highly customizable solutions, empowering businesses to collect, manage, and analyze data while harnessing state-of-the-art machine learning technology.')}
            </p>
            <img src={earth} alt="earth" className="earth-image" />
          </div>
          <div className="hero-buttons">
            <button className="cta-button-secondary">{t('Get Started')}</button>
          </div>
        </div>
        <div className='hero-right'>
          <div className="dropdown-container">
            <div className="dropdown-title">{t('Choose Your Plan')}</div>
            {plans.map((plan, index) => (
              <div key={index} className="dropdown-item" onClick={() => setSelectedPlan(index)}>
                <div className="dropdown-header">
                  <div className="dropdown-icon">{plan.icon}</div>
                  <div className="dropdown-name">{plan.name}</div>
                  <div className="dropdown-arrow">
                    <FaChevronDown />
                  </div>
                </div>
                {selectedPlan === index && <div className="dropdown-details">{plan.details}</div>}
              </div>
            ))}
            <div className="cards-container">
              {cardInfo.map((card, index) => (
                <div key={index} className="card">
                  <div className="card-icon">{card.icon}</div>
                  <div className="card-title">{card.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
