import AboutUs from './ContactUs/AboutUs';
import Footer from './Footer/Footer';
import Hero from './Hero/Hero';
import Join from './Join/Join';
import Programs from './Programs/Programs';
import Taskmanagement from './Taskmanagement/Taskmanagement';

import './home.css';
import Workflow from './workflow/Workflow';
function Home() {
  return (
    <div className="Home">
        <Hero />
        <Taskmanagement/>
       {/* <Programs />    */}    
        <Workflow />  
        <AboutUs/>     
        {/* <Join/> */}
        <Footer/>
    </div>
  );
}

export default Home;