import image1 from "../assets/workflow.png";
import image2 from "../assets/workflow2.png";


export const workflowData = [
  {
    image: image1,
    header:"Creating Value Together",
    review:'• At Syban, we believe in the power of collaboration to drive success. Our Data Management Suite is designed to empower businesses by offering a suite of cloud-based tools that are both highly customizable and easy to integrate. By partnering with consulting businesses, we provide tailored data management solutions that meet the unique needs of their clients. Together, we unlock the full potential of data, enabling organizations to collect, manage, and analyze information more effectively. Our collaborative approach ensures that our partners and their clients can maximize the value derived from their data, fostering growth, and achieving strategic objectives in today’s data-driven world.',
      

  },
  {
    image: image2,
    header:"How We Partner",
    review: "• Our partnership model is centered around close collaboration and mutual benefit. We begin by working closely with consulting businesses to understand the specific needs and challenges of their clients. Together, we design and implement bespoke data management solutions that integrate seamlessly into existing workflows. Syban provides comprehensive training and support to consulting partners, enabling them to incorporate our platform into their own offerings effectively. Our cloud-based solutions are then employed and managed by the end business users, ensuring they have access to the latest in data management technology. By maintaining an open line of communication and fostering a spirit of innovation, we ensure that our partners are equipped with cutting-edge technology and insights to deliver exceptional value to their clients.",
  }
];