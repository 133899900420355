import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  useTheme,
  Modal,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import {
  approveReview,
  getAdminInbox,
  rejectReview,
  getFormForReview
} from "../../api/workFlow";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import i18n from "i118";
import Footer from "homepage/Footer/Footer";

const formatDate = (date) => {
  const dateObject = new Date(date);
  return dateObject.toLocaleString("en-US", {
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

const Admin = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetAdminsQuery();
  const [rejectModal, setRejectModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedform_id, setSelectedform_id] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [formStructData, setFormStructData] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchFormStructures = async () => {
      try {
        const response = await getAdminInbox(token);

        if (!response) {
          throw new Error("Empty response from server");
        }
        const formattedData = response.map((row, index) => {
          return {
            ...row,
            last_modified_date: formatDate(row.last_modified_date["$date"]),
            id: index,
          };
        });
        setFormStructData(formattedData);
      } catch (error) {
        console.error("Error fetching form structures:", error);
      }
    };

    fetchFormStructures();
  }, [shouldRefresh]);

  useEffect(() => {
    setSelectedComment("");
  }, [rejectModal, approveModal]);

  const handleApproveRow = async () => {
    try {
      await approveReview(
        {
          review_id: selectedform_id,
          comment: selectedComment,
        },
        token
      );
      setShouldRefresh(!shouldRefresh);
    } catch (e) { }
    setApproveModal(false);
  };

  const handleViewForm = async (reviewId) => {
    try {
      const response = await fetch("https://api.syban-datacloud.com/flow/inbox/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({ review_id: reviewId }),

      });

      if (!response.ok) {
        throw new Error(`Failed to fetch form data: ${response.status} ${response.statusText}`);
      }

      const formData = await response.json();
      console.log(formData);

      navigate("/view-forms", { state: { formData } });
    } catch (error) {
      console.error("Error fetching form data:", error);
    }
  };

  const columns = [
    { field: "form_id", headerName: t("Form_ID"), flex: 1 },
    { field: "finalizer", headerName: t("Finalizer"), flex: 1 },
    { field: "finalizer_comment", headerName: t("Finalizer_comment"), flex: 1 },
    { field: "last_modified_date", headerName: t("Date"), flex: 1 },
    {
      field: "view",
      headerName: t("View_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleViewForm(params.row.review_id)}
        >
          <FaEye size={20} />
        </Button>
      ),
    },
    {
      field: "approve",
      headerName: t("Approve_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          disabled={params.row.approved || params.row.rejected}
          onClick={() => {
            setApproveModal(true);
            setSelectedform_id(params.row.review_id);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px', // Reduced padding for a smaller button
            fontSize: '0.75rem', // Smaller text size
            minWidth: 'fit-content', // Prevents button from being too wide
            whiteSpace: 'nowrap', // Prevents text from wrapping
          }}
        >
          {t('approve')}
          <IconButton
            size="small"
            sx={{ ml: 0.5, padding: 0, fontSize: '1rem' }} // Smaller icon
          >
            <AiOutlineCheckCircle style={{ color: 'green' }} />
          </IconButton>
        </Button>

      ),
    },
    {
      field: "reject",
      headerName: t("Reject_form"),
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          disabled={params.row.approved || params.row.rejected}
          onClick={() => {
            setRejectModal(true);
            setSelectedform_id(params.row.review_id);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 8px', // Reduced padding for a smaller button
            fontSize: '0.75rem', // Smaller text size
            minWidth: 'fit-content', // Prevents button from being too wide
            whiteSpace: 'nowrap', // Prevents text from wrapping
          }}
        >
          {t('reject')}
          <IconButton
            size="small"
            sx={{ ml: 0.5, padding: 0, fontSize: '1rem' }} // Smaller icon
          >
            <AiOutlineCloseCircle style={{ color: 'red' }} />
          </IconButton>
        </Button>

      ),
    },
  ];

  const handleRejectRow = async () => {
    try {
      await rejectReview(
        {
          review_id: selectedform_id,
          comment: selectedComment,
        },
        token
      );
      setShouldRefresh(!shouldRefresh);
    } catch (e) { }
    setRejectModal(false);
  };

  const tableRTLClass = i18n.language === "en" ? "table-rtl" : "rtl";

  return (
    <div style={{overflowX: 'hidden'}}>
      <Modal
        open={rejectModal}
        onClose={() => setRejectModal(false)}
        aria-labelledby="delete-form-modal"
        aria-describedby="delete-form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: { xs: '90%', sm: 400 }, // Responsive width
            maxWidth: 600, // Max width for larger screens
          }}
        >
          <Typography
            id="delete-form-modal-description"
            variant="h6"
            gutterBottom
          >
            {t("confirmReject")}
          </Typography>
          <Typography
            id="finalize-form-modal-description"
            variant="h6"
            gutterBottom
          >
            {t("EnterComment")}
          </Typography>
          <TextField
            label="Comment"
            variant="outlined"
            value={selectedComment}
            onChange={(e) => setSelectedComment(e.target.value)}
            fullWidth
            autoFocus
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setRejectModal(false)}
              sx={{
                minWidth: 'auto', // Adjust button width
                fontSize: '0.75rem', // Smaller text size
                padding: '6px 12px', // Smaller padding
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleRejectRow}
              sx={{
                ml: 2,
                minWidth: 'auto', // Adjust button width
                fontSize: '0.75rem', // Smaller text size
                padding: '6px 12px', // Smaller padding
              }}
            >
              {t("reject")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={approveModal}
        onClose={() => setApproveModal(false)}
        aria-labelledby="approve-form-modal"
        aria-describedby="approve-form-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: { xs: '90%', sm: 400 }, // Responsive width
            maxWidth: 600, // Max width for larger screens
          }}
        >
          <Typography
            id="approve-form-modal-description"
            variant="h6"
            gutterBottom
          >
            {t("confirmApprove")}
          </Typography>
          <Typography
            id="finalize-form-modal-description"
            variant="h6"
            gutterBottom
          >
            {t("EnterComment")}
          </Typography>
          <TextField
            label="Comment"
            variant="outlined"
            value={selectedComment}
            onChange={(e) => setSelectedComment(e.target.value)}
            fullWidth
            autoFocus
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setApproveModal(false)}
              sx={{
                minWidth: 'auto', // Adjust button width
                fontSize: '0.75rem', // Smaller text size
                padding: '6px 12px', // Smaller padding
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleApproveRow}
              sx={{
                ml: 2,
                minWidth: 'auto', // Adjust button width
                fontSize: '0.75rem', // Smaller text size
                padding: '6px 12px', // Smaller padding
              }}
            >
              {t("approve")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box m="1.5rem 2.5rem">
        <Header title={t("Inbox")} />
        <Box
          mt="40px"
          height="75vh"
          className={tableRTLClass}
          sx={{
            // Ensure the Box container handles overflow properly
            overflow: 'auto', // Ensure the container allows scrolling if needed
            width: '100%',
            position: 'relative', // Ensure proper positioning for overflow
            // Add responsive styles for smaller screens
            '@media (max-width: 600px)': {
              height: '60vh', // Adjust height for smaller screens
            },
            '@media (max-width: 400px)': {
              height: '50vh', // Further adjust height for very small screens
            },
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#6B8A7A",
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
              overflowY: 'auto', // Enable vertical scrolling
              overflowX: 'auto', // Enable horizontal scrolling
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#6B8A7A",
              color: theme.palette.secondary[100],
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={!formStructData.length}
            rows={formStructData}
            columns={columns}
            components={{
              ColumnMenu: CustomColumnMenu,
            }}
            sx={{
              width: '100%',
              height: '100%', // Ensure DataGrid uses the full height of the container
              // Ensure DataGrid handles overflow properly
            }}
          />
        </Box>


        <Footer />
      </Box>
    </div>
  );

};

export default Admin;