import React from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import fillform from "homepage/assets/fillform.png";
import preform from "homepage/assets/preform.png";
import review from "homepage/assets/review.png";
import datadashboard from "homepage/assets/datadashboard.png";
import formgenerator from "homepage/assets/formgeneratorpng.png";
import heroBackground from "homepage/assets/heroBackground.png";
import Footer from "homepage/Footer/Footer";
import TrackWaste from "components/TrackWaste";
import styled from "@emotion/styled";
import Header from "components/Header";
import { Fade, Zoom } from '@mui/material';
// import i18n from "i118";

// Enhanced styled components with modern design enhancements
const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'left',
  padding: '3rem 2rem',
  background: "linear-gradient(to right, rgb(204, 224, 216), rgb(214, 255, 223))",
  color: theme.palette.common.white,
  borderRadius: '20px',
  boxShadow: theme.shadows[20],
  marginBottom: '2rem',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  transform: 'translateY(-10px)',
  animation: 'fadeInUp 0.8s ease-out',

  // Responsive styling
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '2rem 1rem',
    textAlign: 'center',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
    marginBottom: '1rem',
  },
}));

const HeroImage = styled('img')(({ theme }) => ({
  width: '30%',
  borderRadius: '1rem',
  marginRight: '2rem',

  // Responsive styling
  [theme.breakpoints.down('md')]: {
    width: '80%',
    marginRight: 0,
    marginBottom: '1rem',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const HeroTextContainer = styled(Box)(({ theme }) => ({
  flex: '1',

  // Responsive styling
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  color: "#fff",
  textShadow: '3px 3px 12px rgba(0, 0, 0, 0.6)',
  letterSpacing: '0.5px',
  animation: 'fadeInUp 1.2s ease-out',

  // Responsive styling
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  marginBottom: '3rem',
  color: "grey",
  textShadow: '2px 2px 8px rgba(0, 0, 0, 0.3)',
  animation: 'fadeInUp 1.5s ease-out',

  // Responsive styling
  [theme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
    marginBottom: '2rem',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '1rem',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  padding: '0.85rem 2.5rem',
  fontSize: '1.2rem',
  backgroundColor: "#4FAA71",
  color: theme.palette.common.white,
  boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.3)',
  '&:hover': {
    backgroundColor: "#4FAA71",
    transform: 'translateY(-5px)',
  },
  animation: 'fadeInUp 1.8s ease-out',

  // Responsive styling
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    padding: '0.75rem 2rem',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
    padding: '0.5rem 1.5rem',
  },
}));


const CardGrid = styled(Grid)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gap: '2.5rem',
  animation: 'fadeInUp 2s ease-out',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '320px',
  background: 'rgba(255, 255, 255, 0.15)',
  backdropFilter: 'blur(15px)',
  borderRadius: '1.2rem',
  boxShadow: '0 6px 35px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.4s ease, box-shadow 0.4s ease',
  '&:hover': {
    transform: 'scale(1.07)',
    boxShadow: theme.shadows[20],
    background: "rgba(255, 255, 255, 0.3)",
  },
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  height: 200,
  width: '85%',
  borderRadius: '0.7rem',
  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  textAlign: 'center',
  padding: '1.5rem 1rem',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  marginBottom: '0.75rem',
  color: "#34495e",
}));

const CardDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '1rem',
}));

const FullWidthContainer = styled(Box)(({ theme }) => ({
  padding: '4rem 2rem',
  background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
  width: '100%',
  minHeight: '100vh',
  margin: 0,
  overflowX: 'hidden',
  animation: 'fadeIn 0.5s ease-out',
}));

const Dashboard = () => {
  const theme = useTheme();
  const { t, i18n} = useTranslation();
  const navigate = useNavigate(); 

   // Set direction based on current language
   const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  return (
    <>
      <FullWidthContainer>
      <HeroSection  sx={{ direction: direction }}>
          <HeroImage src={heroBackground} alt="Hero Image" />
          <HeroTextContainer  className={direction}>
            <HeroTitle >{t("Welcome to the Dashboard")}</HeroTitle>
            <HeroSubtitle>{t("Manage your data effectively with our tools")}</HeroSubtitle>
            <HeroButton onClick={() => navigate('/formsgroups')}>
              {t("Get Started")}
            </HeroButton>
          </HeroTextContainer>
        </HeroSection>

        {/*<Typography variant="h4" gutterBottom align="center" sx={{ mb: '3rem', color: "#34495e" }}>
          {t("Quick Access")}
  </Typography>*/}

        <CardGrid container spacing={4}>
          <Zoom in={true} timeout={1000}>
            <StyledCard onClick={() => navigate('/formsgroups')}>
              <CardImage image={fillform} title={t("Fill Form")} />
              <CardContentStyled>
                <CardTitle>{t("Fill New Data")}</CardTitle>
                <CardDescription>{t("Add new data with ease")}</CardDescription>
              </CardContentStyled>
            </StyledCard>
          </Zoom>

          <Zoom in={true} timeout={1200}>
            <StyledCard onClick={() => navigate('/myforms')}>
              <CardImage image={preform} title={t("Previous Forms")} />
              <CardContentStyled>
                <CardTitle>{t("Access Previously Filled Forms")}</CardTitle>
                <CardDescription>{t("Review your past entries here.")}</CardDescription>
              </CardContentStyled>
            </StyledCard>
          </Zoom>

          <Zoom in={true} timeout={1400}>
            <StyledCard onClick={() => navigate('/inbox')}>
              <CardImage image={review} title={t("Forms to Review")} />
              <CardContentStyled>
                <CardTitle>{t("Forms to be Reviewed")}</CardTitle>
                <CardDescription>{t("Check and review pending forms.")}</CardDescription>
              </CardContentStyled>
            </StyledCard>
          </Zoom>

          <Zoom in={true} timeout={1600}>
            <StyledCard onClick={() => navigate('/data-dashboard')}>
              <CardImage image={datadashboard} title={t("Data Dashboards")} />
              <CardContentStyled>
                <CardTitle>{t("Data Dashboards")}</CardTitle>
                <CardDescription>{t("Visualize your data effectively.")}</CardDescription>
              </CardContentStyled>
            </StyledCard>
          </Zoom>

          <Zoom in={true} timeout={1800}>
            <StyledCard onClick={() => navigate('/formgenerator')}>
              <CardImage image={formgenerator} title={t("Form Generator")} />
              <CardContentStyled>
                <CardTitle>{t("Form Generator")}</CardTitle>
                <CardDescription>{t("Create custom forms as needed.")}</CardDescription>
              </CardContentStyled>
            </StyledCard>
          </Zoom>
        </CardGrid>

        <Box mt="5rem">
          <TrackWaste />
        </Box>

        <Footer />
      </FullWidthContainer>
    </>
  );
};

export default Dashboard;