import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Box,
  ButtonGroup,
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  CssBaseline,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import i18n from 'i118';
import Footer from 'homepage/Footer/Footer';
import Header from 'components/Header';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6B8A7A',
    },
    secondary: {
      main: '#B7B597',
    },
    background: {
      default: '#F4F5F7',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 400,
      color: '#7D7D7D',
    },
  },
});

const WasteManagementDashboard = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [timeInterval, setTimeInterval] = useState('last_month');
  const [visibleContent, setVisibleContent] = useState('received_waste');

  const token = localStorage.getItem("token");

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('https://api.syban-datacloud.com/dashboard/dashboard/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          dashboard_name: visibleContent,
          time_interval: timeInterval,
          start_date: startDate,
          end_date: endDate
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setChartData(generateChartData(result));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const generateChartData = (data) => {
    if (!data || !data.message) return null;

    if (visibleContent === 'stored_waste') {
      const exitData = data.message[0].exit.map(item => ({
        date: new Date(item["Date Waste Exited Storage Facility"]).toLocaleDateString(),
        'Waste Quantity Exiting Storage Facility': item["Waste Quantity Exiting Storage Facility"]
      }));

      const enterData = data.message[0].enter.map(item => ({
        date: new Date(item["Date Waste Entered Storage Facility"]).toLocaleDateString(),
        'Waste Quantity Entering Storage Facility': item["Waste Quantity Entering Storage Facility"]
      }));

      const dates = [...new Set([...exitData.map(item => item.date), ...enterData.map(item => item.date)])];

      const combinedData = dates.map(date => {
        const exitItem = exitData.find(item => item.date === date) || {};
        const enterItem = enterData.find(item => item.date === date) || {};
        return {
          date,
          'Waste Quantity Exiting Storage Facility': exitItem['Waste Quantity Exiting Storage Facility'] || 0,
          'Waste Quantity Entering Storage Facility': enterItem['Waste Quantity Entering Storage Facility'] || 0
        };
      });

      return combinedData;
    } else if (visibleContent === 'treated_waste') {
      const treatedData = data.message[0].treated_waste.map(item => ({
        date: new Date(item["Process Completion Date"]).toLocaleDateString(),
        'Waste Quantity': item["Waste Quantity"]
      }));

      const dates = [...new Set(treatedData.map(item => item.date))];

      const amounts = dates.map(date => {
        const dailyData = treatedData.filter(item => item.date === date);
        return dailyData.reduce((total, item) => total + item['Waste Quantity'], 0);
      });

      return dates.map((date, index) => ({
        date,
        'Waste Quantity': amounts[index]
      }));
    } else if (visibleContent === 'landfilled_waste') {
      const landfilledData = data.message[0].landfilled_waste.map(item => ({
        date: new Date(item["Disposal date"]).toLocaleDateString(),
        'Waste Quantity': item["Waste Quantity"]
      }));

      const dates = [...new Set(landfilledData.map(item => item.date))];

      const amounts = dates.map(date => {
        const dailyData = landfilledData.filter(item => item.date === date);
        return dailyData.reduce((total, item) => total + item['Waste Quantity'], 0);
      });

      return dates.map((date, index) => ({
        date,
        'Waste Quantity': amounts[index]
      }));
    }

    return data.message.map(item => ({
      'Date of Acceptance': item.destination && item.destination[0] && item.destination[0]['Date of Acceptance'],
      'Amount of Waste at Origin': item.origin.reduce((acc, curr) => acc + parseInt(curr['Amount of Waste']), 0),
      'Amount of Waste at Destination': item.destination.reduce((acc, curr) => acc + parseInt(curr['Amount of Waste']), 0),
    })).filter(item => item !== null);
  };

  useEffect(() => {
    fetchDashboardData();
  }, [token, timeInterval, startDate, endDate, visibleContent]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await fetchDashboardData();
  };

  const isFarsi = i18n.language === 'fa';

  return (
    <div >
      <Box sx={{ px: 2 , overflowX:'hidden'}}>
        <Header title={t("wasteManagementDashboard")} /></Box>
      <ThemeProvider theme={theme}>

        <CssBaseline />

        <Box sx={{ width: '100%' ,  overflowX:'hidden'}}>
          <Container maxWidth={false} >
            <Box mt={4}  sx={{   overflowX:'hidden'}}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper elevation={3}>
                    <Box p={3}>
                      <ButtonGroup variant="contained" fullWidth>
                        <Button
                          onClick={() => setVisibleContent('received_waste')}
                          style={{
                            backgroundColor: visibleContent === 'received_waste' ? '#7AAB85' : '#f5f5f5',
                            color: visibleContent === 'received_waste' ? '#fff' : '#000',
                          }}
                        >
                          {t("receivedWaste")}
                        </Button>
                        <Button
                          onClick={() => setVisibleContent('stored_waste')}
                          style={{
                            backgroundColor: visibleContent === 'stored_waste' ? '#7AAB85' : '#f5f5f5',
                            color: visibleContent === 'stored_waste' ? '#fff' : '#000',
                          }}
                        >
                          {t("storedWaste")}
                        </Button>
                        <Button
                          onClick={() => setVisibleContent('treated_waste')}
                          style={{
                            backgroundColor: visibleContent === 'treated_waste' ? '#7AAB85' : '#f5f5f5',
                            color: visibleContent === 'treated_waste' ? '#fff' : '#000',
                          }}
                        >
                          {t("treatedWaste")}
                        </Button>
                        <Button
                          onClick={() => setVisibleContent('landfilled_waste')}
                          style={{
                            backgroundColor: visibleContent === 'landfilled_waste' ? '#7AAB85' : '#f5f5f5',
                            color: visibleContent === 'landfilled_waste' ? '#fff' : '#000',
                          }}
                        >
                          {t("landfilledWaste")}
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Box mt={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={3}>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                              <TextField
                                fullWidth
                                label={t("timeInterval")}
                                select
                                value={timeInterval}
                                onChange={(e) => setTimeInterval(e.target.value)}
                              >
                                <MenuItem value="last_day">{t("lastDay")}</MenuItem>
                                <MenuItem value="last_week">{t("lastWeek")}</MenuItem>
                                <MenuItem value="last_month">{t("lastMonth")}</MenuItem>
                                

                              </TextField>
                            </Grid>
                            {/*<Grid item xs={2}>
                              <Button variant="outlined" color="primary" fullWidth type="submit">
                                {t("submit")}
                              </Button>
                        </Grid>*/}
                          </Grid>
                        </form>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper elevation={3}>
                      <Box p={3}>
                        {loading ? (
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                          </Box>
                        ) : error ? (
                          <Typography color="error">{error}</Typography>
                        ) : chartData ? (
                          <Box height={400}>
                            <ResponsiveBar
                              data={chartData}
                              keys={['Waste Quantity', 'Waste Quantity Entering Storage Facility', 'Waste Quantity Exiting Storage Facility', 'Amount of Waste at Origin', 'Amount of Waste at Destination']}
                              indexBy="date"
                              margin={{ top: 50, right: 260, bottom: 70, left: 150 }}
                              padding={0.3}
                              colors={{ scheme: 'nivo' }}
                              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                              axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Date',
                                legendPosition: 'middle',
                                legendOffset: 40,
                              }}
                              axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Waste Quantity',
                                legendPosition: 'middle',
                                legendOffset: -120,
                              }}
                              labelSkipWidth={20}
                              labelSkipHeight={20}
                              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                              legends={[
                                {
                                  dataFrom: 'keys',
                                  anchor: 'bottom-right',
                                  direction: 'column',
                                  justify: false,
                                  translateX: 120,
                                  translateY: 0,
                                  itemsSpacing: 2,
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemDirection: 'left-to-right',
                                  itemOpacity: 0.85,
                                  symbolSize: 20,
                                  effects: [
                                    {
                                      on: 'hover',
                                      style: {
                                        itemOpacity: 1,
                                      },
                                    },
                                  ],
                                },
                              ]}
                              animate={true}
                              motionStiffness={90}
                              motionDamping={15}
                              theme={{
                                tooltip: {
                                  container: {
                                    fontSize: '13px',
                                  },
                                },
                                axis: {
                                  ticks: {
                                    text: {
                                      fontSize: '12px',
                                      fill: '#555',
                                    },
                                  },
                                },
                              }}
                            />
                          </Box>
                        ) : (
                          <Typography>{t("noData")}</Typography>
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default WasteManagementDashboard;