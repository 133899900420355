import image1 from "../assets/taskmanagement.png";
import image2 from "../assets/taskmanagement2.png";
import image3 from "../assets/visualization.png";
import image4 from "../assets/kpi.png";
import image5 from "../assets/ML-Powered.png";


export const taskmanagementData = [
  {
    image: image5,
    header:"ML-Powered Data Analytics",
    review: 'Our platform boasts ML-Powered Data Analytics, leveraging ‘advanced machine learning’ capabilities to provide comprehensive data insights. Features such as predictive analytics, anomaly detection, clustering, and natural language processing (NLP) are integrated into our platform. These powerful analytics tools enable businesses to forecast trends, identify irregularities, group similar data points, and extract meaningful information from text data. Coupled with robust visualization tools, these features ensure that complex data is presented in an accessible and actionable format.',
  },
  {
    image: image1,
    header: "Data Collection Forms ",
    review:
      "Create ‘customized forms’ tailored to a wide range of needs, from simple personal submissions by employees within a company to extensive surveys in marketing or citizen science. These forms facilitate both individual inputs and collective data-gathering efforts, making them suitable for team collaborations, organization-wide initiatives, or large-scale surveys. A key feature of our platform is its robust approval system, which enables managers to assess and approve submitted forms efficiently. This ensures that data collection processes are streamlined, accurate, and aligned with organizational goals.",

  },
  {
    image: image2,
    header:"Seamless Data Integration",
    review: 'Our platform includes a powerful feature called ‘Data Integration Plug-Ins’. We develop code that allows API-based integration with existing data collection and generation platforms. This enables seamless importing and exporting of data, ensuring that your existing systems and our tools work together harmoniously. Whether you need to import data from external sources or export it for further analysis and reporting, our Data Integration Plug-Ins makes the process effortless and efficient. ',
  },
  {
    image: image3,
    header:"Data Visualization",
    review: 'Our platform features ‘Live Data Dashboards’, offering real-time data visualization and asset tracking to keep you informed and in control. These dashboards can be used out-of-the-box or customized to meet your specific needs, providing a dynamic and interactive way to monitor and analyze data. With multi-level access, different team members can view and interact with the data relevant to their roles. Additionally, our platform allows you to share and export visualizations, making it easy to include insightful data representations in reports and presentations. This feature ensures that you have a powerful tool for tracking performance, making data-driven decisions, and effectively communicating insights across your organization.',
  },
  {
    image: image4,
    header:"KPI-Driven Data Insights",
    review: 'Our platform offers KPI-Driven Data Insights, empowering businesses to measure and track their performance effectively. Using either ‘out-of-the-box KPIs’ or ‘custom KPIs’ developed specifically for individual customers, we transform collected and ingested data into actionable insights. This feature allows you to monitor key performance indicators that are most relevant to your business goals, providing a clear and comprehensive view of your progress.',
  },
  
 

];