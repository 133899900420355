import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Grid,
  Container,
  Icon,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import dashboard1 from "homepage/assets/dashboard1.png";
import dashboard2 from "homepage/assets/dashboard2.png";
import dashboard3 from "homepage/assets/dashboard3.png";
import chart from "homepage/assets/chart.png";
import healthSafetyImage from "homepage/assets/healthSafetyImage.png";
import Footer from "homepage/Footer/Footer";
import Header from "components/Header";

// Additional icons
import WasteIcon from '@mui/icons-material/Delete';

// Hero Section
const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '45vh',
  justifyContent: 'space-between',
  background: 'linear-gradient(to right, rgb(224, 241, 255), rgb(212, 232, 217))',
  padding: '1rem',
  borderRadius: '1rem',
  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  marginBottom: '2rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: 'auto',
    padding: '1rem',
    textAlign: 'center', // Center text and button
  },
}));

const HeroText = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  color: '#fff',
  marginBottom: '1rem',
  marginLeft: '5rem',
  textShadow: '3px 3px 12px rgba(0, 0, 0, 0.4)',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
    marginLeft: '0',
  },
}));

const HeroSubText = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  color: '#858585',
  fontWeight: '700',
  marginBottom: '2rem',
  marginLeft: '5rem',
  textShadow: '3px 3px 12px rgba(0, 0, 0, 0.4)',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    marginLeft: '0',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  padding: '0.75rem 1.5rem',
  fontSize: '0.9rem',
  backgroundColor: '#FF8723',
  borderRadius: '10px',
  color: '#fff',
  fontWeight: '600',
  margin: '0 auto', // Center button horizontally
  display: 'block',
  '&:hover': {
    backgroundColor: '#FC9200',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '1rem', // Space between button and text on smaller screens
  },
}));

const HeroImageBox = styled(Box)(({ theme }) => ({
  flex: '1',
  marginLeft: '5rem',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    marginTop: '1rem',
  },
  '& img': {
    width: '60%',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

// Styled Button for Dashboard Sections
const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '1rem',
  padding: '1.5rem',
  borderRadius: '12px',
  boxShadow: '0 6px 12px 5px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease-in-out, backgroundColor 0.3s',
  backgroundColor: '#FFFFFF',
  fontWeight: 'bold',
  color: '#707070',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    transform: 'scale(1.08)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  marginTop: '1rem',
  fontSize: '1.2rem',
  fontWeight: '700',
  textAlign: 'center',
  color: "#1B4242",
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '60%',
  height: '9rem',
  borderRadius: '8px',
  overflow: 'hidden',
  marginBottom: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '1rem',
  height: '35vh', // Adjust height on smaller screens
  borderRadius: '1rem',
  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  marginTop: '2rem',
  maxWidth: '100%', // Ensure full width
  [theme.breakpoints.down('sm')]: {
    height: 'auto', // Allow height to adjust based on content
    padding: '0.5rem', // Reduce padding on smaller screens
    marginTop: '1rem', // Reduce margin-top on smaller screens
  },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: "#000",
  marginBottom: '1.5rem',
  textAlign: 'center',
}));

// New Section Styled Components
const NewSection = styled(Box)(({ theme }) => ({
  margin: '3rem 0',
  padding: '3rem 2rem',
  backgroundColor: '#F9F9F9',
  borderRadius: '1rem',
  boxShadow: '0 6px 12px rgba(0,0,0,0.3)',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    marginBottom: '5rem', // Ensure there's enough space for the footer
  },
}));

const NewSectionContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const NewSectionText = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  color: theme.palette.text.primary,
  marginLeft: '1rem',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    textAlign: 'center',
    marginBottom: '1rem',
  },
}));

const NewSectionIcon = styled(Icon)(({ theme }) => ({
  fontSize: '4rem',
  color: theme.palette.primary.main,
  marginRight: '1rem',
  [theme.breakpoints.down('md')]: {
    marginRight: '0',
  },
}));

const NewSectionImage = styled(Box)(({ theme }) => ({
  flex: '1',
  marginLeft: '2rem',
  width: '100%',
  height: 'auto', // Ensures the height adjusts based on the width
  borderRadius: '0.5rem',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    marginTop: '1rem',
  },
  '& img': {
    width: '100%', // Ensures the image fills the width of the container
    height: 'auto', // Maintains aspect ratio
  },
  // Additional styling for larger screens
  [theme.breakpoints.up('lg')]: {
    marginLeft: '2rem', // Reapply margin for larger screens if needed
  },
}));

// Main Component
const DataDashboard = () => {
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Set direction based on current language
  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  return (
    <div className={direction}>
      <Box sx={{ minHeight: '100vh', px: 5, width: '100%', py: 5 }}> {/* Ensures full width */}

        {/* Hero Section */}
        <HeroSection>
          <Box>
            <HeroText>{t("Transform Your Data Experience")}</HeroText>
            <HeroSubText>{t("Unlock insights with our intuitive dashboards tailored to your business needs.")}</HeroSubText>
            <HeroButton onClick={() => navigate('/wastemanagementdashboard')}>{t("Get Started")}</HeroButton>
          </Box>
          <HeroImageBox>
            <img src={chart} alt="Hero" />
          </HeroImageBox>
        </HeroSection>

        {/* Dashboard Sections */}
        <ContainerBox sx={{ direction: direction }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <StyledButton onClick={() => navigate('/wastemanagementdashboard')}>
                <ButtonText>{t("Waste Management")}</ButtonText>
                <ImageBox>
                  <img src={dashboard1} alt="Waste Management Logo" style={{ width: '100%' }} />
                </ImageBox>
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledButton onClick={() => navigate('/daily')}>
                <ButtonText>{t("CRM and Finance")}</ButtonText>
                <ImageBox>
                  <img src={dashboard2} alt="CRM and Finance Logo" style={{ width: '100%' }} />
                </ImageBox>
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledButton onClick={() => navigate('/safety-dashboards')}>
                <ButtonText>{t("Health and Safety")}</ButtonText>
                <ImageBox>
                  <img src={dashboard3} alt="Health and Safety Logo" style={{ width: '100%' }} />
                </ImageBox>
              </StyledButton>
            </Grid>
          </Grid>
        </ContainerBox>

       
        {/* Footer */}
        <Footer />
      </Box>
    </div>
  );
};

export default DataDashboard;