import React, { useState } from 'react';
import { taskmanagementData } from 'homepage/data/taskmanagerData';
import { useTranslation } from "react-i18next";
import { FaRocket, FaChartLine, FaTools } from 'react-icons/fa';
import './Taskmanagement.css';

const Taskmanagement = () => {
    const { t, i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    const icons = {
        'feature1': <FaRocket />,
        'feature2': <FaChartLine />,
        'feature3': <FaTools />,
    };

    // Check if the current language is Farsi (or any other RTL language)
    const isRTL = i18n.language === 'fa'; // 'fa' is the code for Farsi

    return ( 
        <div className={`taskmanagement-page ${isRTL ? 'rtl' : 'ltr'}`}>
            <aside className="taskmanagement-sidebar">
                <h1>{t('Our Cutting-Edge Features')}</h1>
                <p>{t('Revolutionize your workflow with our advanced tools and seamless integration.')}</p>
            </aside>

            <main className="taskmanagement-content">
                <div className="tabs">
                    {taskmanagementData.map((item, index) => (
                        <div
                            key={index}
                            className={`tab ${activeTab === index ? 'active' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            {t(item.header)}
                        </div>
                    ))}
                </div>
                <div className="tab-content">
                    <div className="tab-item">
                        <div className="taskmanagement-icon">
                            {icons[taskmanagementData[activeTab].icon] || <FaTools />}
                        </div>
                        
                        <h2>{t(taskmanagementData[activeTab].header)}</h2>
                        <p>{t(taskmanagementData[activeTab].review)}</p>
                    </div>
                    
                </div>
            </main>
        </div>
    );
};

export default Taskmanagement;
