// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#AFC7AD',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9c27b0',
      contrastText: '#fff',
    },
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 20px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px 15px',
        },
      },
    },
     // Enhanced customization for Dialog
     MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '30px', // Increased padding for better spacing
          borderRadius: '16px', // More rounded corners
          backgroundColor: '#f7f7f7', // Light background color
          border: '1px solid #ddd', // Subtle border
        },
      },
    },
    // Enhanced customization for DialogTitle
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.3rem', // Larger font size
          fontWeight: 700, // Bolder font weight
          borderBottom: '1px solid #e0e0e0', // Bottom border
          marginBottom: '15px', // More margin bottom
          padding: '20px 30px', // Increased padding
          // backgroundColor: '#439151', 
          color: '#6B8A7A', // Text color
          borderRadius: '15px', // More curved border radius
          
        },
      },
    },
    // Enhanced customization for DialogContent
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px 30px', // Increased padding
        },
      },
    },
    // Enhanced customization for DialogActions
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '15px 30px', // Increased padding
          borderTop: '1px solid #e0e0e0', // Top border
          backgroundColor: '#f7f7f7', // Background color
        },
      },
    },
    // Enhanced customization for Typography
    MuiTypography: {
      styleOverrides: {
        body1: {
          marginBottom: '15px', // More margin bottom for spacing
          fontSize: '1.1rem', // Larger font size
        },
      },
    },


    },
 

});

export default theme;
