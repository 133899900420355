import React, { useState, useMemo } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,
  Box, IconButton, Collapse, Chip, TablePagination, TextField, InputAdornment
} from '@mui/material';
import { ExpandMore, ExpandLess, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const DataTable = ({ data = [], columns = [] }) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

  const [expandedRow, setExpandedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      columns.some((column) =>
        row[column].toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [data, columns, searchQuery]);

  // return (
  //   <Box sx={{ mt: 4, overflowX: 'auto', p: 2, }}>
  //     <TextField
  //       variant="outlined"
  //       placeholder={t("Search...")}
  //       fullWidth
  //       value={searchQuery}
  //       onChange={(e) => setSearchQuery(e.target.value)}
  //       sx={{ mb: 2, direction: direction }}
  //       InputProps={{
  //         startAdornment: (
  //           <InputAdornment position="start">
  //             <Search />
  //           </InputAdornment>
  //         ),
  //         sx: { borderRadius: '16px' },
  //       }}
  //     />
  //     <TableContainer
  //       component={Paper}
  //       sx={{
  //         borderRadius: '16px',
  //         boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
  //         overflowX: 'auto',
  //         '& table': {
  //           minWidth: '600px', // Minimum width for horizontal scrolling
  //           '@media (max-width:600px)': {
  //             minWidth: 'auto',
  //           },
  //         },
  //       }}
  //     >
  //       <Table sx={{ direction: direction, width: '100%', overflowX: 'auto' }}>
  //         <TableHead>
  //           <TableRow
  //             sx={{
  //               backgroundColor: '#7AAB85',
  //               color: '#ffffff',
  //               '& th': {
  //                 fontWeight: 'bold', width: '100%',
  //                 fontSize: '14px',
  //                 textAlign: 'center',
  //                 padding: '12px',
  //                 whiteSpace: 'nowrap',
  //                 '@media (max-width:600px)': {
  //                   fontSize: '12px', // Smaller font size for smaller screens
  //                 },
  //               },
  //             }}
  //           >
  //             {columns.map((column) => (
  //               <TableCell key={column} sx={{ color: '#ffffff', direction: direction, width: '100%' }}>
  //                 {t(`${column}`)}
  //               </TableCell>
  //             ))}
  //             <TableCell />
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {filteredData.length > 0 ? (
  //             filteredData
  //               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //               .map((row, index) => (
  //                 <React.Fragment key={index}>
  //                   <TableRow
  //                     onClick={() => handleRowClick(index)}
  //                     sx={{
  //                       cursor: 'pointer', width: '100%',
  //                       '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
  //                       '&:hover': { backgroundColor: '#e0e0e0' },
  //                       transition: 'background-color 0.3s ease',
  //                       direction: direction,
  //                       '@media (max-width:600px)': {
  //                         fontSize: '12px', // Adjust font size on smaller screens
  //                       },
  //                     }}
  //                   >
  //                     {columns.map((column) => (
  //                       <TableCell
  //                         key={column}
  //                         sx={{
  //                           padding: '16px', width: '100%',
  //                           textAlign: 'center',
  //                           whiteSpace: 'nowrap',
  //                           overflow: 'hidden',
  //                           textOverflow: 'ellipsis',
  //                           direction: direction,
  //                           '@media (max-width:600px)': {
  //                             padding: '8px', // Smaller padding on smaller screens
  //                           },
  //                         }}
  //                       >
  //                         <Typography variant="body2" noWrap>
  //                           {t(`${row[column]}`)}
  //                         </Typography>
  //                       </TableCell>
  //                     ))}
  //                     <TableCell sx={{ width: '48px', textAlign: 'center', direction: direction, }}>
  //                       <IconButton size="small">
  //                         {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
  //                       </IconButton>
  //                     </TableCell>
  //                   </TableRow>
  //                   <TableRow sx={{ direction: direction }}>
  //                     <TableCell
  //                       colSpan={columns.length + 1}
  //                       sx={{
  //                         padding: 0,
  //                         display: expandedRow === index ? 'table-cell' : 'none',
  //                         direction: direction,
  //                       }}
  //                     >
  //                       <Collapse in={expandedRow === index} >
  //                         <Box
  //                           sx={{
  //                             p: 2,
  //                             backgroundColor: '#f9f9f9',
  //                             direction: direction,
  //                           }}
  //                         >
  //                           <Typography variant="h6" sx={{ direction: direction }}>{t('Details')}:</Typography>
  //                           <Box
  //                             sx={{
  //                               display: 'flex',
  //                               flexWrap: 'wrap',
  //                               gap: 1,
  //                               direction: direction,
  //                             }}
  //                           >
  //                             {Object.entries(row).map(([key, value]) => (
  //                               <Chip
  //                                 key={key}
  //                                 label={`${t(key)}: ${value}`}
  //                                 sx={{ margin: '4px' }}
  //                               />
  //                             ))}
  //                           </Box>
  //                         </Box>
  //                       </Collapse>
  //                     </TableCell>
  //                   </TableRow>

  //                 </React.Fragment>
  //               ))
  //           ) : (
  //             <TableRow>
  //               <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center', padding: '16px' }}>
  //                 <Typography variant="body1" color="textSecondary">{t('No data available')}</Typography>
  //               </TableCell>
  //             </TableRow>
  //           )}
  //         </TableBody>
  //       </Table>
  //       <TablePagination
  //         rowsPerPageOptions={[5, 10, 25]}
  //         component="div"
  //         count={filteredData.length}
  //         rowsPerPage={rowsPerPage}
  //         page={page}
  //         onPageChange={handleChangePage}
  //         onRowsPerPageChange={handleChangeRowsPerPage}
  //       />
  //     </TableContainer>
  //   </Box>
  // );

  return (
    <Box sx={{ mt: 4, overflowX: 'auto', p: 2 }}>
      <TextField
        variant="outlined"
        placeholder={t("Search...")}
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          mb: 2,
          direction: direction,
          '& input': {
            padding: '10px', // Adjust padding for smaller screens
          },
          '@media (max-width:600px)': {
            '& input': {
              padding: '8px', // Smaller padding on smaller screens
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          sx: { borderRadius: '16px' },
        }}
      />
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '16px',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
          overflowX: 'auto',
          '@media (max-width:600px)': {
            '& table': {
              minWidth: 'auto',
            },
          },
        }}
      >
        <Table sx={{ direction: direction, width: '100%' }}>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#7AAB85',
                color: '#ffffff',
                '& th': {
                  fontWeight: 'bold',
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '12px',
                  whiteSpace: 'nowrap',
                  '@media (max-width:600px)': {
                    fontSize: '12px', // Smaller font size for smaller screens
                    padding: '8px', // Smaller padding on smaller screens
                  },
                },
              }}
            >
              {columns.map((column) => (
                <TableCell key={column} sx={{ color: '#ffffff', direction: direction }}>
                  {t(`${column}`)}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={() => handleRowClick(index)}
                      sx={{
                        cursor: 'pointer',
                        '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                        '&:hover': { backgroundColor: '#e0e0e0' },
                        transition: 'background-color 0.3s ease',
                        '@media (max-width:600px)': {
                          fontSize: '12px', // Adjust font size on smaller screens
                          '& td': {
                            padding: '8px', // Smaller padding on smaller screens
                          },
                        },
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column}
                          sx={{
                            padding: '16px',
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            direction: direction,
                            '@media (max-width:600px)': {
                              padding: '8px', // Smaller padding on smaller screens
                            },
                          }}
                        >
                          <Typography variant="body2" noWrap>
                            {t(`${row[column]}`)}
                          </Typography>
                        </TableCell>
                      ))}
                      <TableCell sx={{ width: '48px', textAlign: 'center', direction: direction }}>
                        <IconButton size="small">
                          {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ direction: direction }}>
                      <TableCell
                        colSpan={columns.length + 1}
                        sx={{
                          padding: 0,
                          display: expandedRow === index ? 'table-cell' : 'none',
                          direction: direction,
                        }}
                      >
                        <Collapse in={expandedRow === index}>
                          <Box
                            sx={{
                              p: 2,
                              backgroundColor: '#f9f9f9',
                              direction: direction,
                            }}
                          >
                            <Typography variant="h6" sx={{ direction: direction }}>{t('Details')}:</Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 1,
                                direction: direction,
                              }}
                            >
                              {Object.entries(row).map(([key, value]) => (
                                <Chip
                                  key={key}
                                  label={`${t(key)}: ${value}`}
                                  sx={{ margin: '4px' }}
                                />
                              ))}
                            </Box>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} sx={{ textAlign: 'center', padding: '16px' }}>
                  <Typography variant="body1" color="textSecondary">{t('No data available')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
  
};

export default DataTable;