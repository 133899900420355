import axios from "axios";

// Base URL for the backend API
const API_BASE_URL = "https://api.syban-datacloud.com/files/";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to handle file uploads
export const uploadFileApi = async ({ file, form_id }, token) => {
  const formData = new FormData();
  formData.append("form_id", form_id);
  formData.append("file", file);

  try {
    const response = await api.post(
      "upload/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // Using token from the function arguments
        },
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    throw error.response ? error.response.data : error.message; // Handle error response
  }
};

export const downloadFileApi = async ({ form_id, file_name }, token) => {
  const requestBody = {
    form_id,
    file_name,
  };

  try {
    const response = await api.post(
      "download/",
      requestBody,
      {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};


export const deleteFileApi = async ({ form_id, file_name }, token) => {
  const requestBody = {
    form_id,
    file_name,
  };

  try {
    const response = await api.delete(
      "delete/",
      {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        data: requestBody, // `data` is used to send request body with DELETE request
      }
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

