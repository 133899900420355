import React from 'react';
import '../workflow/aboutus.css';
import aboutus from "../assets/aboutus.png";
import { useTranslation } from "react-i18next";
import { FaLightbulb, FaHandshake, FaRocket } from 'react-icons/fa';

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'fa'; // Check if the current language is Farsi

    return (
        <div className="about-us-wrapper" dir={isRTL ? 'rtl' : 'ltr'}>
            <div className="about-us-content-container">
                <div className="about-us-content">
                    <div className="about-us-text">
                        <h2 className="about-us-title">{t('About Us')}</h2>
                        <p className="about-us-description">
                            {t('At Syban, we specialize in delivering cutting-edge, cloud-based data management solutions. Our suite of tools empowers businesses to collect, manage, and analyze data efficiently, leveraging state-of-the-art machine learning technology. Committed to innovation and collaboration, we provide customizable and cost-effective solutions that integrate seamlessly into existing workflows, helping our clients unlock the full potential of their data.')}
                        </p>
                        <div className="about-us-features">
                            <div className="feature-card">
                                <FaLightbulb className="feature-icon" />
                                <h3>{t('Innovation')}</h3>
                                <p>{t('We are constantly innovating to bring the latest technology solutions to our clients.')}</p>
                            </div>
                            <div className="feature-card">
                                <FaHandshake className="feature-icon" />
                                <h3>{t('Collaboration')}</h3>
                                <p>{t('Our team works closely with clients to tailor solutions to their specific needs.')}</p>
                            </div>
                            <div className="feature-card">
                                <FaRocket className="feature-icon" />
                                <h3>{t('Efficiency')}</h3>
                                <p>{t('Our tools are designed to streamline processes and improve data management efficiency.')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="about-us-image">
                        <img src={aboutus} alt={t('About Us Image')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
