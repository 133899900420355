import { Typography, Box, useTheme } from "@mui/material";
import React from "react";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 3,
        borderRadius: "20px",
        background: "#bdbdbd6c",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        textAlign: 'center'
      }}
    >
      <Typography
        variant="h2"
        color="#496E53"
        fontWeight="bold"
        sx={{
          mb: "10px",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)"
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        color="#848484"
        sx={{
          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
          fontSize: '1.25rem'
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
