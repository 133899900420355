import React, { useState, useEffect } from "react";
import Header from "components/Header";
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, TextField, Typography, Grid, Button, Collapse, Card, CardContent, CardActions, CssBaseline } from "@mui/material";
import { useTheme } from "@emotion/react";
import { FaEye, FaRegEdit } from 'react-icons/fa';
import { MdOutlineLibraryAdd } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import i18n from "i118";
import Footer from "homepage/Footer/Footer";
import chart from "homepage/assets/formGroups.png";
import styled from "@emotion/styled";

// Hero Section
const HeroSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '40vh',
  padding: '2rem',
  background: 'linear-gradient(to right, #a1c4fd, #c2e9fb)',
  boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
  borderRadius: '20px',
  marginBottom: '3rem',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
    height: 'auto',
  },
}));

const HeroText = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#fff',
  marginBottom: '1.5rem',
  textShadow: '4px 4px 16px rgba(0, 0, 0, 0.3)',
  animation: 'fadeIn 1.2s ease-in-out',

  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
}));

const HeroSubText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#000',
  fontWeight: '500',
  marginBottom: '2rem',
  maxWidth: '800px',
  textShadow: '3px 3px 10px rgba(0, 0, 0, 0.3)',
  animation: 'slideIn 1.5s ease-in-out',
  '@keyframes slideIn': {
    from: { transform: 'translateX(-50px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem',
    margin: '0 1rem',
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  padding: '0.85rem 2rem',
  fontSize: '1rem',
  backgroundColor: '#ff9800',
  borderRadius: '20px',
  color: '#fff',
  fontWeight: '700',
  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#ff6f00',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.3)',
    transform: 'translateY(-3px)',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 auto',
  },
}));

const HeroImageBox = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '55%',
    borderRadius: '1rem',
    transform: 'scale(1)',
    transition: 'transform 0.5s ease', // Smooth scaling animation
    '&:hover': {
      transform: 'scale(1.05)', // Slight zoom on hover for interactivity
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: '1.5rem',
  },
}));

const FormGroups = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [formGroups, setFormGroups] = useState([]);
  const [selectedFormGroup, setSelectedFormGroup] = useState(null);
  const [currentFormGroupName, setCurrentFormGroupName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://api.syban-datacloud.com/forms/list-form-groups/");
        const data = await response.json();
        setFormGroups(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSelectChange = async (event) => {
    const newFormGroupName = event.target.value;
    await fetchForms(newFormGroupName);
  };

  const handleLanguageChange = async () => {
    await fetchForms(currentFormGroupName); // Use the current state value
  };

  const fetchForms = async (formGroupName) => {
    try {
      setLoading(true);
      setError(null);

      const formGroupNameToUse = formGroupName ?? currentFormGroupName;

      if (formGroupName) {
        setCurrentFormGroupName(formGroupNameToUse);
      }

      const formGroup = formGroups.find(
        (group) => group.name[t("en")] === formGroupNameToUse
      );

      if (formGroup) {
        const response = await fetch(
          `https://api.syban-datacloud.com/forms/list-forms/${formGroup.ref}/${i18n.language}`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch form data');
        }

        const data = await response.json();
        setResponseData(data);
        setSelectedFormGroup(formGroup);
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, handleLanguageChange]);

  const handleEditClick = async (formStructId) => {
    try {
      setLoading(true);
      setError(null);

      // Pass the formStructId to the Formsofx2 route
      navigate(`/formsofx2/${formStructId}`);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const isFarsi = i18n.language === 'fa';
  const direction = isFarsi ? 'rtl' : 'rtl';

  return (
    <Box m="1.5rem 2.5rem" sx={{ direction: isFarsi ? 'rtl' : 'ltr', overflowX:'hidden' }}>
      <CssBaseline />
      <HeroSection> 
        <HeroImageBox>
          <img src={chart} alt="Hero" />
        </HeroImageBox>
        <Box>
          <HeroText>{t("Complete Your Journey in Just a Few Steps")}</HeroText>
          <HeroSubText>{t("Get started by filling out the forms below. We’ve made the process simple, fast, and secure. Your information helps us serve you better. Let’s make it happen!")}</HeroSubText>
          <HeroButton onClick={() => navigate('/wastemanagementdashboard')}>{t("Get Started")}</HeroButton>
        </Box>

      </HeroSection>
      <Box display="flex" justifyContent="center" sx={{ direction: direction }}>
      <TextField
  select
  label={t('Select Title')}
  value={selectedFormGroup ? selectedFormGroup.name[t("en")] : ""}
  onChange={handleSelectChange}
  variant="outlined"
  fullWidth
  margin="normal"
  InputLabelProps={{
    shrink: false,  // Ensures label stays inside the field area
    style: {
      color: 'rgba(0, 0, 0, 0.87)', // Set consistent label color
      direction: direction, 
    },
  }}
  sx={{
    direction: direction,
    '& .MuiInputBase-input': {
      fontFamily: isFarsi ? 'Vazir, Arial' : 'Arial',
      fontSize: isFarsi ? '1.1rem' : '1rem',
      color: 'rgba(0, 0, 0, 0.87)', // Ensure input text color is consistent
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Border color when focused
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)', // Keep the label color consistent when focused
    },
  }}
  MenuProps={{
    PaperProps: {
      sx: {
        maxHeight: 200,
        maxWidth: 'calc(100vw - 32px)',
        overflowX: 'hidden',
        zIndex: 1300,
        direction: direction, 
        '@media (max-width: 600px)': {
          maxHeight: '150px',
          overflowY: 'auto',
        },
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null, // Ensures dropdown anchors properly
  }}
>
  {formGroups.map((group) => (
    <MenuItem key={group.ref} value={group.name[t("en")]} sx={{ direction: direction }}>
      {isFarsi ? group.name[t("fa")] : group.name[t("en")]}
    </MenuItem>
  ))}
</TextField>





      </Box>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {responseData.length > 0 && (
        <Grid container spacing={3} justifyContent="center">
          {responseData.map((form, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                key={index}
                sx={{
                  backgroundImage: "none",
                  backgroundColor: "#F0F0F0",
                  borderRadius: "0.55rem",
                  direction: isFarsi ? 'rtl' : 'ltr',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontFamily: isFarsi ? 'Vazir, Arial' : 'Arial',
                      fontSize: isFarsi ? '1.2rem' : '1rem'
                    }}
                  >
                    {t(`${form.title}`)}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: isFarsi ? 'Vazir, Arial' : 'Arial',
                      fontSize: isFarsi ? '1.1rem' : '1rem'
                    }}
                  >
                    {form.form_description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ flexDirection: 'column', alignItems: 'center' }}>
                  <Button variant="primary" size="small" onClick={() => handleEditClick(form.form_struct_id)}><FaRegEdit size={20} color="#6B8A7A" /></Button>
                  <Button onClick={() => setIsExpanded(!isExpanded)} sx={{ mt: 1 }}>
                    {isExpanded ? 'Collapse' : 'Expand'}
                  </Button>
                </CardActions>
                <Collapse
                  in={isExpanded}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    color: theme.palette.neutral[300],
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: isFarsi ? 'Vazir, Arial' : 'Arial',
                        fontSize: isFarsi ? '1.1rem' : '1rem'
                      }}
                    >
                      Form Group: {form.form_struct_group}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <Footer />
    </Box>
  );
};

export default FormGroups;