import React, { useState } from 'react';
import styled from 'styled-components';

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #CFE0CF;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ArticleImage = styled.img`
  max-width: 100%;
  height: 16rem;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ArticleTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const ArticleContent = styled.p`
  font-size: 18px;
  color: #555;
  line-height: 1.6;
`;

const ReadMoreButton = styled.button`
  background-color: #4a7c51;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  
  &:hover {
    background-color: #45a049;
  }
`;

const Article = ({ image, title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <ArticleContainer>
      <ArticleImage src={image} alt={title} />
      <ArticleTitle>{title}</ArticleTitle>
      <ArticleContent>{isExpanded ? content : `${content.substring(0, 200)}...`}</ArticleContent>
      <ReadMoreButton onClick={toggleExpand}>
        {isExpanded ? 'Read Less' : 'Read Entire Article'}
      </ReadMoreButton>
    </ArticleContainer>
  );
}

export default Article;
