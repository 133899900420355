import axios from "axios";

const API_BASE_URL = "https://api.syban-datacloud.com/";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Helper function to get token from local storage
const getToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refreshToken');
const setToken = (token) => localStorage.setItem('token', token);

api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const response = await axios.post(`${API_BASE_URL}auth/refresh`, { token: refreshToken });
        if (response.data.token) {
          setToken(response.data.token);
          error.config.headers['Authorization'] = `Token ${response.data.token}`;
          return axios.request(error.config);
        }
      }
    }
    return Promise.reject(error);
  }
);

// Helper function to attach Authorization header
const authHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Token ${token}` } : {};
};

const token = localStorage.getItem("token");

export const getSpecificFormApi = async (formId, lng) => {
  try {
    const response = await api.get(`forms/form_struct/${formId}/${lng}`, {
      headers: authHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching specific form:', error);
    throw error.response ? error.response.data : error;
  }
};


export const handleViewForm = async (formId, lng) => {
  try {
    const response = await api.post(`forms/form-info/`,  {form_id: formId}, {
      headers: authHeaders(),
    });
    console.log(response.data);
    return response.data.value[0];
   
  } catch (error) {
    console.error('Error fetching specific form:', error);
    throw error.response ? error.response.data : error;
  }
};
export const getChoicesApi = async (endpoint) => {
  try {
    const response = await api.get(endpoint, {
      headers: authHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching choices:', error);
    throw error.response ? error.response.data : error;
  
    }
  };


export const uploadFormApi = async (body) => {
  try {
    const token = getToken();
    if (!token) throw new Error("Token not found");
    console.log('Token for uploadFormApi:', `"${token}"`);
    const response = await api.post(
      `forms/form-uploader/`,
      body,
      {
        headers: {
          Authorization: `Token ${token}`
,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading form:', error);
    throw error.response ? error.response.data : error;
  }
};

export const updateFormApi = async (form, token) => {
  const url = 'https://api.syban-datacloud.com/forms/update/';

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Token ${token}`,
  };

  const payload = {
    form_struct_id: form.form_struct_id,
    form_struct_group: form.form_struct_group,
    title: form.title,
    logo_url: form.logo_url,
    form_description: form.form_description,
    language: form.language,
    pdf_url: form.pdf_url,
    uploader_username: form.uploader_username,
    form_id: form.form_id,
    finalized: form.finalized,
    reviewers: form.reviewers,
    approved_by_all_reviewers: false,
    file_urls: [],


    data: form.data,
  };

  try {
    console.log('Updating form with URL:', url);
    console.log('Payload:', JSON.stringify(payload, null, 2));
    console.log('Headers:', JSON.stringify(headers, null, 2));

    const response = await axios.put(url, payload, { headers });
    console.log('Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating form:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : new Error('API call failed');
  }
};

export const getSingleFormApi = async (form_id) => {
  try {
    const token = getToken();
    if (!token) throw new Error("Token not found");
    console.log('Token for getSingleFormApi:', token);
    const response = await api.post(
      `forms/form-info/`,
      { form_id },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching single form:', error);
    throw error.response ? error.response.data : error;
  }
};